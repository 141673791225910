import { Drawer, Button, Table, Row, Col, Typography, Tag, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  PlusOutlined,
  QuestionCircleTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { Money, useApi, useDrawer } from "antdash";
import NewRunner from "../components/forms/NewRunner";
import runnersApi from "../api/runners";
import { useHistory } from "react-router-dom";
import { formatDistanceToNow, parseISO } from "date-fns";

const { Title, Text } = Typography;

const Runners = () => {
  const breakpoint = useBreakpoint();
  const history = useHistory();

  const runners = useApi({
    apiFunc: runnersApi.list,
    unpackName: "runners",
    defaultData: [],
    defaultParams: { "filter{user.is_active}": true },
    debug: true,
  });

  const newRunnerDrawer = useDrawer();

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "user",
      render: (user) => (
        <Space>
          <Text>
            {user.first_name} {user.last_name}
          </Text>
          {!user.is_active && <Tag color="red">Deactivated</Tag>}
        </Space>
      ),
    },
    {
      title: "Float",
      key: "float",
      align: "end",
      render: (runner) => (
        <Space>
          <Money
            amount={runner.float}
            currency={runner.float_currency}
            style={{ cursor: "pointer" }}
          />
          {runner.float_confirmation_needed ? (
            <QuestionCircleTwoTone twoToneColor="#d46b08" />
          ) : (
            <CheckCircleTwoTone twoToneColor="#00C50B" />
          )}
        </Space>
      ),
    },
    {
      title: "Online",
      render: ({ online, last_active }) =>
        online ? (
          <Text>Online</Text>
        ) : (
          <Text type="secondary">
            Offline (
            {last_active
              ? formatDistanceToNow(parseISO(last_active), { addSuffix: true })
              : "never"}
            )
          </Text>
        ),
    },
  ];

  return (
    <>
      <Drawer
        title="Add Runner"
        width={breakpoint.md ? 500 : "100%"}
        onClose={newRunnerDrawer.close}
        visible={newRunnerDrawer.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <NewRunner
          closeDrawer={newRunnerDrawer.close}
          refresh={runners.refresh}
        />
      </Drawer>
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>
            Runners
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={newRunnerDrawer.open}
            icon={<PlusOutlined />}
          >
            Add Runner
          </Button>
        </Col>
        <Col span={24}>
          <Table
            onRow={(record) => ({
              onClick: () => {
                history.push(`/runners/${record.id}`);
              },
            })}
            scroll={{ x: "max-content" }}
            pagination={false}
            dataSource={runners.data}
            columns={columns}
            rowKey={(record) => record.id}
            loading={runners.loading}
            size={breakpoint.md ? "large" : "small"}
            footer={() => {
              const active = runners.params["filter{user.is_active}"];
              return (
                <Button
                  type="link"
                  size="small"
                  onClick={() =>
                    runners.setParams((c) => ({
                      "filter{user.is_active}": !active,
                    }))
                  }
                >
                  Show {active ? "Deactivated" : "Activated"}
                </Button>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Runners;
