import { Modal, Button, message, Space, Typography } from "antd";
import { useState } from "react";
import usersApi from "../api/users";

const { Text } = Typography;

const StaffModal = ({ user, setUser, onAction, visible, close }) => {
  const [loading, setLoading] = useState(false);

  const toggleUserActive = async (user) => {
    const toggled = !user.is_active;
    const prefix = toggled ? "" : "de";
    setLoading(true);
    const res = await usersApi.setActive(user.id, toggled);
    setLoading(false);
    if (res.ok) {
      message.success(`User ${prefix}activated.`);
      setUser(res.data.user);
      onAction();
      close();
    } else {
      message.error(`Error ${prefix}activating user.`);
    }
  };

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const resetPassword = async () => {
    setResetPasswordLoading(true);
    const res = await usersApi.resetPassword(user.id);
    setResetPasswordLoading(false);
    if (res.ok) {
      setNewPassword(res.data.auto_password);
      message.success(`Password reset.`);
    } else {
      setNewPassword(null);
      message.error(`Error resetting password.`);
    }
  };

  if (!user) {
    return <></>;
  }

  const title = `${user.first_name} ${user.last_name} (${user.username})`;

  return (
    <Modal
      title={title}
      centered
      visible={visible}
      onCancel={close}
      footer={null}
    >
      <Space direction="vertical">
        <Button
          type="outline"
          loading={resetPasswordLoading}
          onClick={resetPassword}
        >
          Reset Password
        </Button>
        <Space>
          {newPassword && (
            <>
              <Text>New Password: </Text>
              <Text copyable>{newPassword}</Text>
            </>
          )}
        </Space>
        {user.is_active ? (
          <Button
            type="outline"
            onClick={() => toggleUserActive(user)}
            danger
            loading={loading}
          >
            Deactivate User
          </Button>
        ) : (
          <Button
            type="outline"
            onClick={() => toggleUserActive(user)}
            loading={loading}
          >
            Reactivate user
          </Button>
        )}
      </Space>
    </Modal>
  );
};

export default StaffModal;
