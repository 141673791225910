const statusColors = {
  ACTIVE: "geekblue",
  WINNER: "green",
  PLACED: "cyan",
  LOSER: "red",
  REMOVED: "purple",
  PUSH: "purple",
};

export default statusColors;
