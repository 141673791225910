import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

const falsey = (x) => {
  if (x?.length === 0) return true;
  // if (x.keys().length === 0) return true;
  if (x === null) return true;
  if (x === false) return true;
  if (x === 0) return true;
  if (x === "") return true;
  return false;
};

const Queue = ({
  next,
  Item,
  emptyMessage,
  EmptyIcon = CheckCircleOutlined,
}) => {
  return next.loading || falsey(next.data) ? (
    <div style={styles.container}>
      {next.loading ? (
        <LoadingOutlined spin style={{ fontSize: 80 }} />
      ) : (
        <EmptyIcon style={{ fontSize: 80 }} onClick={next.refresh} />
      )}
      <Space
        direction="vertical"
        align="center"
        style={{ marginTop: 20, height: 60 }}
        size="middle"
      >
        {next.loading ? (
          "Loading..."
        ) : (
          <>
            {emptyMessage}
            <Button type="link" onClick={next.refresh}>
              Refresh
            </Button>
          </>
        )}
      </Space>
    </div>
  ) : (
    <Item next={next} />
  );
};

export default Queue;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#c0c0c0",
    alignItems: "center",
    padding: 16,
    height: "100%",
  },
};
