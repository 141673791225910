import { client } from "antdash";

const list = (data = {}) => client.get("runners", data);

const get = (id) => client.get(`runners/${id}`);

const create = (data) => client.post("runners", data);

const update = (id, data) => client.patch(`runners/${id}`, data);

const transfer = (from_runner_id, amount, transfer_type, to_runner_id = null) =>
  client.post(`runner_transfer`, {
    from_runner_id,
    amount,
    transfer_type,
    to_runner_id,
  });

const runnersApi = {
  list,
  get,
  create,
  update,
  transfer,
};

export default runnersApi;
