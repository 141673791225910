import { useState } from "react";
import selectionsApi from "../api/selections";
import {
  Button,
  Col,
  Row,
  Card,
  Space,
  Typography,
  Divider,
  Tag,
  message,
} from "antd";
import {
  parseISO,
  isToday,
  format,
  addMinutes,
  roundToNearestMinutes,
} from "date-fns";
import { betType, StatusTag, formatOdds, statusColors } from "betui";
import { Money, useApi, DatePicker } from "antdash";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Queue from "../components/Queue";

const { Text, Title } = Typography;

const formatDate = (date) => {
  const day = isToday(date) ? "Today" : format(date, "PPP, ");
  const time = format(date, "p");
  return `${day} ${time}`;
};

const getNow = () => roundToNearestMinutes(new Date(), { nearestTo: 5 });

const Item = ({ next }) => {
  const now = new Date();
  const [settleAfter, setSettleAfter] = useState(getNow());
  const disabledDate = (current) => current < now;
  const breakpoint = useBreakpoint();

  const settleAfterAddMins = (mins) =>
    setSettleAfter((value) => addMinutes(value, mins));

  const submitResult = async (result) => {
    next.setLoading(true);
    const res = await selectionsApi.settle(selection.id, result);
    console.log("RES:", res);
    if (res.ok) {
      message.success(`${selection.runner}: ${result}`, 1);
      next.refresh();
    } else {
      message.error("Error settling selection.");
    }
  };

  const submitSettleAfter = async () => {
    next.setLoading(true);
    const res = await selectionsApi.update(selection.id, {
      settle_after: settleAfter,
    });
    console.log("RES:", res);
    if (res.ok) {
      next.refresh();
    } else {
      message.error("Error updating selection.");
    }
  };

  const selection = next.data ? next.data[0] : null;

  const settlementOptions = selection.each_way
    ? ["WINNER", "PLACED", "LOSER", "REMOVED"]
    : selection.handicap_market
    ? ["WINNER", "LOSER", "PUSH", "REMOVED"]
    : ["WINNER", "LOSER", "REMOVED"];

  return (
    <Card style={{ paddingBottom: 10 }}>
      <Row gutter={[16, 16]} align="middle">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Title level={4} style={{ marginBottom: 0 }}>
                {selection.event}
              </Title>
            </Col>
            <Col>
              <Title level={4} style={{ marginBottom: 0 }} type="secondary">
                {selection.event_type}
              </Title>
            </Col>
          </Row>
          <Divider />
        </Col>
        <Col>
          <Title level={4}>{selection.runner}</Title>
        </Col>
        <Col span={24}>
          <Text>
            Start Time:{" "}
            <Text type="secondary">
              {formatDate(parseISO(selection.event_start_time))}
            </Text>
          </Text>
          <br />
          {selection.handicap_market && (
            <Text>
              Handicap Market:{" "}
              <Text type="secondary">
                {selection.handicap_market.toString()}
              </Text>
              <br />
            </Text>
          )}
          {selection.each_way && (
            <Text>
              Market EW Divisor:{" "}
              <Text type="secondary">{selection.ew_divisor}</Text>
              <br />
            </Text>
          )}
          <Text>
            Rule 4: <Text type="secondary">{selection.rule_4}</Text>
          </Text>
          <br />
          <Text strong>
            Settle After: {formatDate(parseISO(selection.settle_after))}
          </Text>
          <br />
          <br />
        </Col>
        <Col span={24}>
          {settlementOptions.map((status) => (
            <Tag
              color={statusColors[status]}
              style={{ cursor: "pointer" }}
              onClick={() => submitResult(status)}
              key={status}
            >
              {status}
            </Tag>
          ))}
        </Col>
        <Col span={24}>
          <Divider />
          <Text type="secondary">Push Settlement Time</Text>
        </Col>
        <Col>
          <Space>
            <Button size="small" onClick={() => settleAfterAddMins(5)}>
              + 5 mins
            </Button>
            <Button size="small" onClick={() => settleAfterAddMins(15)}>
              + 15 mins
            </Button>
            <Button size="small" onClick={() => settleAfterAddMins(60)}>
              + 1 hr
            </Button>
          </Space>
        </Col>
        <Col>
          <Space>
            <DatePicker
              style={{ width: !breakpoint.xs && 280 }}
              showTime
              value={settleAfter}
              size="small"
              showSecond={false}
              allowClear={false}
              disabledDate={disabledDate}
              format={formatDate}
              onChange={setSettleAfter}
            />
            <Button size="small" type="primary" onClick={submitSettleAfter}>
              Push
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

const NextSettlement = () => {
  const now = new Date();
  const next = useApi({
    apiFunc: selectionsApi.list,
    defaultParams: {
      "filter{result}": "ACTIVE",
      "filter{settle_manually}": true,
      "filter{settle_after.lt}": now.toISOString(),
      sort: ["settle_after", "id"],
      per_page: 1,
    },
    unpackName: "selections",
    defaultData: [],
  });
  console.log("DATA", next.data);

  return (
    <Queue
      next={next}
      Item={Item}
      emptyMessage={"No selections for manual settlement."}
    />
  );
};

export default NextSettlement;
