import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Splash = () => {
  return (
    <div style={styles.container}>
      <LoadingOutlined spin />
    </div>
  );
};

export default Splash;

const styles = {
  container: {
    minHeight: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
