import { useState, useEffect } from "react";
import { Button, Space, message } from "antd";
import { AndroidFilled, AppleFilled } from "@ant-design/icons";
import usersApi from "../api/users";

const ios = "https://apps.apple.com/gb/app/runner-agent/id1588556525";

const InstallAppButtons = () => {
  const [links, setLinks] = useState({ android: null, ios });

  const getLinks = async () => {
    const res = await usersApi.installLink("android");
    if (res.ok) {
      setLinks({ android: res.data.url, ios });
    } else {
      message.error(`Error getting Android download link.`);
    }
  };

  useEffect(() => {
    getLinks();
  }, []);

  return (
    <Space>
      <Button href={links.android} icon={<AndroidFilled />}>
        Android
      </Button>
      <Button href={links.ios} icon={<AppleFilled />}>
        iOS
      </Button>
    </Space>
  );
};

export default InstallAppButtons;
