import { useState } from "react";
import { Table, Row, Col, Typography } from "antd";
import { useParams } from "react-router-dom";
import betsApi from "../api/bets";
import { betType, formatOdds, ResultEditable } from "betui";
import transfersApi from "../api/transfers";
import {
  Money,
  useApi,
  usePages,
  apiUtils,
  RangeOrDatePicker,
  prettyDate,
} from "antdash";
import runnersApi from "../api/runners";
import RunnerCard from "../components/RunnerCard";
import selectionsApi from "../api/selections";
import flagsApi from "../api/flags";
import FlagsTable from "../components/FlagsTable";
import { routes } from "../../App";
import AppBreadcrumb from "../components/AppBreadcrumb";

const { Text } = Typography;

const transferColumns = [
  {
    title: "Date",
    key: "date",
    dataIndex: "created",
    render: prettyDate,
  },
  {
    title: "Type",
    key: "description",
    dataIndex: "description",
  },
  {
    title: "Delta",
    key: "delta",
    render: (transfer) => (
      <Money amount={transfer.amount} currency={transfer.amount_currency} />
    ),
  },
];

const RunnerDetail = () => {
  const { id } = useParams();

  const runnerDetail = useApi({
    apiFunc: runnersApi.get,
    unpackName: "runner",
    defaultParams: id,
  });

  const today = new Date();
  const runnerFilter = { "filter{runner.id}": id };
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const deltaRunnerField = (fieldName, delta) => {
    if (parseFloat(delta) === 0) {
      console.log("ZERO DELTA");
      return;
    }
    runnerDetail.setData((state) => {
      const updated = { ...state };
      updated[fieldName] = parseFloat(state[fieldName]) + parseFloat(delta);
      return updated;
    });
  };

  const betList = usePages({
    apiFunc: betsApi.list,
    unpackName: "bets",
    defaultParams: {
      ...apiUtils.dateFilter(today, today),
      ...runnerFilter,
    },
    defaultPerPage: 20,
    debug: true,
  });

  const transferList = usePages({
    apiFunc: transfersApi.list,
    unpackName: "float_transfers",
    defaultParams: {
      ...apiUtils.dateFilter(today, today),
      ...runnerFilter,
    },
    defaultPerPage: 20,
    debug: true,
  });

  const flags = usePages({
    apiFunc: flagsApi.list,
    unpackName: "flags",
    defaultParams: {
      sort: ["-id"],
      ...runnerFilter,
    },
    defaultPerPage: 10,
    debug: true,
  });

  console.log(flags.data);

  const setMultipleParams = (func) => {
    betList.setParams(func);
    transferList.setParams(func);
  };

  const onDepositWithdraw = (res) => {
    transferList.refresh();
    deltaRunnerField("float", res.data.amount);
  };

  const columns = [
    {
      title: "Bet",
      render: (bet) => (
        <div style={{ overflow: "hidden" }}>
          <Text type="secondary">{betType(bet)}</Text>
          <div>
            {bet.bet_selections.map(({ id, selection, odds }) => (
              <>
                <Text ellipsis>{selection.runner}</Text>
                <br />
              </>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: "Stake",
      key: "stake",
      align: "center",
      sorter: (a, b) => a.stake - b.stake,
      render: (bet) => (
        <Money amount={bet.stake} currency={bet.stake_currency} />
      ),
    },
    {
      title: "Payout",
      key: "payout",
      align: "center",
      sorter: (a, b) => a.expected_payout - b.expected_payout,
      render: (bet) => (
        <Money amount={bet.expected_payout} currency={bet.stake_currency} />
      ),
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <AppBreadcrumb routes={[routes.RUNNERS, routes.RUNNER_DETAIL]} />
        </Col>
        <Col span={24} xl={12}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={24}>
              <RunnerCard
                runner={runnerDetail.data}
                setRunner={runnerDetail.setData}
                onDepositWithdraw={onDepositWithdraw}
                loading={runnerDetail.loading}
              />
            </Col>
            <Col span={24}>
              <FlagsTable
                flags={flags}
                excludeCols={["runner", "handled"]}
                pagination={flags.paginationProps}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24} xl={12}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col>
              <RangeOrDatePicker setParams={setMultipleParams} />
            </Col>
            <Col span={24}>
              <Table
                scroll={{ y: 300, x: "max-content" }}
                onRow={(record) => ({
                  onClick: () => {
                    setModalData(record);
                    setModalVisible(true);
                  },
                })}
                pagination={{ ...betList.paginationProps }}
                dataSource={betList.data}
                columns={columns}
                loading={betList.loading}
                rowKey={(record) => record.id}
                footer={() => `Total bets: ${betList.totalResults}`}
                size="small"
              />
            </Col>
            <Col span={24}>
              <Table
                scroll={{ y: 240, x: "max-content" }}
                pagination={{ ...transferList.paginationProps }}
                dataSource={transferList.data}
                columns={transferColumns}
                loading={transferList.loading}
                rowKey={(record) => record.id}
                footer={() => `Total transfers: ${transferList.totalResults}`}
                size="small"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RunnerDetail;
