import { useState, useRef } from "react";
import { Row, Col, Button, Form, Input, Select, Typography } from "antd";
import { useSubmit, getCurrencySymbol, useApiList, Money } from "antdash";
import runnersApi from "../../api/runners";

const { Option } = Select;

const { Text } = Typography;

const TRANSFER_TYPES = {
  DEPOSIT: { value: "DEPOSIT", label: "Deposit" },
  WITHDRAW: { value: "WITHDRAW", label: "Withdraw" },
  TO_RUNNER: { value: "TO_RUNNER", label: "To Runner" },
};

const TRANSFER_TYPE_OPTIONS = Object.values(TRANSFER_TYPES);

const TransferForm = ({ runner, onTransferSuccess }) => {
  const amountRef = useRef(null);

  const runners = useApiList(runnersApi, "runners", {
    "filter{float_currency}": runner.float_currency,
  });
  const [showRunners, setShowRunners] = useState(false);

  const submit = async (formData) => {
    const res = await runnersApi.transfer(
      runner.id,
      formData.amount,
      formData.transfer_type,
      formData.to_runner_id
    );
    console.log(res);
    return res;
  };
  const { form, submitting, onFinish } = useSubmit(submit, onTransferSuccess);

  return (
    <Form
      form={form}
      requiredMark="optional"
      onFinish={onFinish}
      initialValues={{
        transfer_type: TRANSFER_TYPES.DEPOSIT.value,
      }}
    >
      <Row justify="center" gutter={16}>
        <Col span={24} md={8}>
          <Form.Item
            name="amount"
            rules={[
              {
                required: true,
                message: "Amount required.",
              },
            ]}
            extra={
              <Text type="secondary">
                Current Balance:{" "}
                <Money
                  amount={runner.float}
                  currency={runner.float_currency}
                  style={{ color: "inherit", fontSize: "inherit" }}
                />
              </Text>
            }
          >
            <Input
              type="number"
              min={0}
              step="0.01"
              addonBefore={getCurrencySymbol(runner.float_currency)}
              ref={amountRef}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={8}>
          <Form.Item name="transfer_type" rules={[{ required: true }]}>
            <Select
              options={TRANSFER_TYPE_OPTIONS}
              onChange={(v) => {
                console.log(v);
                setShowRunners(v === TRANSFER_TYPES.TO_RUNNER.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={8} style={!showRunners && { display: "none" }}>
          <Form.Item name="to_runner_id" rules={[{ required: showRunners }]}>
            <Select
              showSearch
              optionFilterProp="children"
              loading={runners.loading}
            >
              {runners.list
                .filter(({ id }) => id !== runner.id)
                .map(({ id, float_currency, user }) => (
                  <Option value={id} currency={float_currency} key={id}>
                    {user.first_name} {user.last_name} ({float_currency})
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={4} align="middle">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TransferForm;
