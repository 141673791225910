import { client } from "antdash";

const create = (data) => client.post("bets", data);
const list = (data = {}) => client.get("bets", data);
const get = (id) => client.get(`bets/${id}`);
const update = (id, data) => client.patch(`bets/${id}`, data);

const betsApi = {
  create,
  list,
  get,
  update,
};

export default betsApi;
