import {
  Col,
  Row,
  Space,
  Drawer,
  Typography,
  Table,
  Divider,
  Button,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Money, MoneyTitle, useApi, useDrawer } from "antdash";
import { format } from "date-fns";
import { useContext } from "react";
import extraApi from "../api/extra";
import Splash from "../components/Splash";
import AppContext from "../context/AppContext";

const { Title, Text, Paragraph } = Typography;

// "past_months": [
//     {"created__month": 11, "created__year": 2021, "total": 1201.7033},
//     {"created__month": 10, "created__year": 2021, "total": 1086.8557},
// ],

const billingDate = (item) => {
  const date = new Date(item.created__year, item.created__month - 1);
  return format(date, "LLLL u");
};

const Billing = () => {
  const paymentDrawer = useDrawer();

  const { config } = useContext(AppContext);

  const breakpoint = useBreakpoint();

  const billing = useApi({ apiFunc: extraApi.billing, debug: true });
  if (!billing.data) {
    return <Splash />;
  }

  const columns = [
    {
      title: "Period",
      render: billingDate,
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "end",
      render: (total) => (
        <Money amount={total} currency={billing.data.currency} />
      ),
    },
  ];

  return (
    <>
      <Drawer
        width={breakpoint.md ? 500 : "100%"}
        title="Make Payment"
        visible={paymentDrawer.visible}
        onClose={paymentDrawer.close}
      >
        <Paragraph>
          Please pay by bank transfer to one of the accounts listed below.
        </Paragraph>
        <Paragraph>
          Balance due:{" "}
          <Money
            amount={billing.data.balance > 0 ? billing.data.balance : 0}
            currency={billing.data.currency}
          />
        </Paragraph>
        <Paragraph>
          <b>GBP</b>
          <br />
          Beneficiary:{" "}
          <Text code copyable>
            Rowan Egan
          </Text>
          <br />
          Reference/Message:{" "}
          <Text code copyable>
            {config.TEAM_ID}
          </Text>
          <br />
          Account:{" "}
          <Text code copyable>
            02530554
          </Text>
          <br />
          Sort Code:{" "}
          <Text code copyable>
            04-00-75
          </Text>
        </Paragraph>
        <Paragraph>
          <b>EUR</b>
          <br />
          Beneficiary:{" "}
          <Text code copyable>
            Rowan Egan
          </Text>
          <br />
          Reference/Message:{" "}
          <Text code copyable>
            {config.TEAM_ID}
          </Text>
          <br />
          IBAN:{" "}
          <Text code copyable>
            LT92 3250 0732 5629 0495
          </Text>
          <br />
          BIC:{" "}
          <Text code copyable>
            REVOLT21
          </Text>
        </Paragraph>
        <Paragraph>
          Any funds received in excess of balance due will added as credit to
          your account.
        </Paragraph>
        <Paragraph>
          Payments received in a currency different to your account base
          currency ({billing.data.currency}) will be credited at the ECB
          exchange rate on date of receipt.
        </Paragraph>
      </Drawer>
      <Row
        gutter={[16, 16]}
        style={{ maxWidth: 700, marginLeft: "auto", marginRight: "auto" }}
        align="middle"
        justify="space-between"
      >
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>
            Billing
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={paymentDrawer.open}
            // icon={<PlusOutlined />}
          >
            Make Payment
          </Button>
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <div>
                <Title level={5} style={{ marginBottom: 0 }}>
                  Current Period
                </Title>
                <Text type="secondary">
                  {billingDate(billing.data.current_period)}
                </Text>
              </div>
            </Col>
            <Col>
              <MoneyTitle
                level={2}
                amount={billing.data.current_period.total}
                currency={billing.data.currency}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <div>
                <Title level={5} style={{ marginBottom: 0 }}>
                  {billing.data.balance > 0 ? "Balance Due" : "Account Credit"}
                </Title>
              </div>
            </Col>
            <Col>
              <MoneyTitle
                level={2}
                amount={Math.abs(billing.data.balance)}
                currency={billing.data.currency}
                style={{
                  color: billing.data.balance > 0 ? "#cf1322" : "#3f8600",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Title level={5}>Past Months</Title>
          <Table
            rowKey={(r) => `${r.created__month}_${r.created__year}`}
            columns={columns}
            dataSource={billing.data.past_months}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Billing;
