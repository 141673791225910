import { client } from "antdash";

const create = (data) => client.post("flags", data);
const list = (data = {}) => client.get("flags", data);
const get = (id) => client.get(`flags/${id}`);
const update = (id, data) => client.patch(`flags/${id}`, data);

const flagsApi = {
  create,
  list,
  get,
  update,
};

export default flagsApi;
