const defaultConfig = {
  BASE_CURRENCY: "EUR",
  CURRENCY_SYMBOLS: {
    EUR: "€",
    GBP: "£",
    USD: "$",
  },
  TEAM_ID: "",
  DEMO: false,
};

export default defaultConfig;
