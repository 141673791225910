import { useContext, useState } from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { UserOutlined, LockOutlined, IdcardOutlined } from "@ant-design/icons";
import { authApi, useSubmit } from "antdash";
import AppContext from "../context/AppContext";

const { Text } = Typography;

const Login = ({ logo, logoAlt, setUser, config }) => {
  const [errors, setErrors] = useState([]);

  const submit = (formData) => authApi.login(formData);
  const success = (res, formData) => {
    setUser(res.data, formData.remember);
  };
  const error = (res) => {
    setErrors(Object.values(res.data));
  };
  const { form, submitting, onFinish } = useSubmit(submit, success, error);

  return (
    <div className="login-page">
      <img
        className="logo"
        src={logo}
        alt={logoAlt}
        style={{ height: 55, marginBottom: 30 }}
      />
      <Form form={form} name="login" onFinish={onFinish} autoComplete="off">
        <Form.Item>
          <Input
            prefix={<IdcardOutlined className="site-form-item-icon" />}
            value={config?.TEAM_ID}
            disabled
            autoCapitalize="none"
          />
        </Form.Item>
        <Form.Item name="username" rules={[{ required: true }]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            autoCapitalize="none"
          />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>

        {errors.map((x, index) => (
          <Text type="danger" key={index}>
            {x}
          </Text>
        ))}

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="login-form">Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={submitting}
          >
            Log In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
