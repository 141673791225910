import React, { useState } from "react";
import { Modal, Input, Button, Form } from "antd";
import { FlagOutlined, EditOutlined, LineOutlined } from "@ant-design/icons";
import { useSubmit } from "antdash";

const AddText = ({ title, defaultText, submit, ButtonComponent }) => {
  const [visible, setVisible] = useState(false);

  const success = () => {
    setVisible(false);
    form.resetFields();
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  return (
    <>
      {ButtonComponent ? (
        <ButtonComponent onClick={() => setVisible(true)} />
      ) : (
        <Button
          icon={<EditOutlined />}
          size="small"
          onClick={() => setVisible(true)}
        >
          {title}
        </Button>
      )}
      <Modal
        title={title}
        visible={visible}
        onCancel={() => setVisible(false)}
        okButtonProps={{ loading: submitting }}
        onOk={form.submit}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{ text: defaultText }}
        >
          <Form.Item name="text">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddText;
