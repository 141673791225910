import { useState } from "react";
import {
  Form,
  Col,
  Row,
  Input,
  Button,
  Result,
  Typography,
  Checkbox,
} from "antd";
import { useSubmit } from "antdash";
import staffApi from "../api/staff";

const { Paragraph } = Typography;

const NewStaffMember = ({ closeDrawer, refresh }) => {
  const [createdData, setCreatedData] = useState(null);
  const submit = (formData) => staffApi.create(formData);

  const success = (res) => {
    setCreatedData(res.data);
    refresh();
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  if (createdData) {
    const { user } = createdData;
    return (
      <Result
        status="success"
        title="Staff User Created"
        subTitle={
          <Paragraph
            type="secondary"
            copyable={{
              text: `Username: ${user.username}, Password: ${createdData.auto_password}`,
              tooltips: ["copy details", "copied"],
            }}
          >
            Username: {user.username}, Password: {createdData.auto_password}
          </Paragraph>
        }
        extra={[
          <Button
            type="primary"
            key="close"
            onClick={() => {
              closeDrawer();
              setCreatedData(null);
            }}
          >
            Done
          </Button>,
          <Button
            key="another"
            onClick={() => {
              setCreatedData(null);
            }}
          >
            Add Another
          </Button>,
        ]}
      />
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onFinish}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: "Please enter a name." }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please enter a username" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="is_superuser"
            extra="Superusers can create staff accounts, view customer ID documenets and create other superusers."
            valuePropName="checked"
          >
            <Checkbox>Superuser</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} align="middle">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Add Staff User
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewStaffMember;
