import { useState, useEffect } from "react";
import { Space, Button, Tooltip, Dropdown, Menu } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import {
  addDays,
  startOfMonth,
  startOfWeek,
  isEqual,
  differenceInCalendarDays,
  endOfWeek,
  endOfMonth,
} from "date-fns";
import { apiUtils, DatePicker } from "antdash";
import useQueryDateRange from "../tools/useQueryDateRange";

const { RangePicker } = DatePicker;

const RangeOrDatePicker = ({ dates, setDates, setParams, dateFieldName }) => {
  const today = new Date();
  // const [dates, setDates] = useQueryDateRange([today, today]);
  const [rangeMode, setRangeMode] = useState(!isEqual(dates[0], dates[1]));

  useEffect(() => {
    setParams((current) => ({
      ...current,
      ...apiUtils.dateFilter(dates[0], dates[1], dateFieldName),
    }));
  }, [dates]);

  const pickDate = (date) => setDates([date, date]);

  const delta = Math.abs(differenceInCalendarDays(dates[0], dates[1])) + 1;

  return (
    <Space>
      {rangeMode ? (
        <Space>
          <Tooltip title={`Back ${delta} days.`} placement="bottom">
            <Button
              onClick={() =>
                setDates([addDays(dates[0], -delta), addDays(dates[1], -delta)])
              }
              icon={<DoubleLeftOutlined />}
            />
          </Tooltip>
          <RangePicker
            allowClear={false}
            value={dates}
            inputReadOnly
            onChange={setDates}
            ranges={{
              Today: [today, today],
              "This Week": [startOfWeek(today), endOfWeek(today)],
              "This Month": [startOfMonth(today), endOfMonth(today)],
            }}
          />
          <Tooltip title={`Forward ${delta} days.`} placement="bottom">
            <Button
              onClick={() =>
                setDates([addDays(dates[0], delta), addDays(dates[1], delta)])
              }
              icon={<DoubleRightOutlined />}
            />
          </Tooltip>
        </Space>
      ) : (
        <Space>
          <Button
            onClick={() => pickDate(addDays(dates[0], -1))}
            icon={<DoubleLeftOutlined />}
          />
          <DatePicker
            inputReadOnly
            value={dates[0]}
            onChange={(date) => pickDate(date)}
            allowClear={false}
          />
          <Button
            onClick={() => pickDate(addDays(dates[0], 1))}
            icon={<DoubleRightOutlined />}
          />
        </Space>
      )}
      {rangeMode ? (
        <Button
          onClick={() => {
            pickDate(dates[0]);
            setRangeMode(false);
          }}
        >
          Day
        </Button>
      ) : (
        <Dropdown.Button
          onClick={() => setRangeMode(true)}
          overlay={
            <Menu>
              {/* "This Week": [startOfWeek(today), endOfWeek(today)], */}
              {/* "This Month": [startOfMonth(today), endOfMonth(today)], */}
              <Menu.Item
                onClick={() => {
                  setDates([startOfWeek(today), endOfWeek(today)]);
                  setRangeMode(true);
                }}
                key="week"
              >
                This Week
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setDates([startOfMonth(today), endOfMonth(today)]);
                  setRangeMode(true);
                }}
                key="month"
              >
                This Month
              </Menu.Item>
            </Menu>
          }
        >
          Range
        </Dropdown.Button>
      )}
    </Space>
  );
};

export default RangeOrDatePicker;
