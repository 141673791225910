import React, { useState } from "react";
import { Rate, Table, Tooltip, Space, Typography, Spin } from "antd";
import { useHistory } from "react-router-dom";
import {
  CheckOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  PaperClipOutlined,
  MinusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import NullCell from "../components/NullCell";
import { prettyDate, BreakLines, tableUtils } from "antdash";
import formatFlagType from "../tools/formatFlagType";
import flagsApi from "../api/flags";

const { Text } = Typography;

const { sortAlpha, sortDate, getFilterValues, sortNum } = tableUtils;

const formatName = ({ runner: { user } }) =>
  `${user.first_name} ${user.last_name}`;

const FlagsTable = ({ flags, excludeCols = [], ...props }) => {
  const history = useHistory();

  const [handledLoading, setHandledLoading] = useState(null);
  const markHandled = async (flag, handled) => {
    setHandledLoading(flag.id);
    const res = await flagsApi.update(flag.id, { handled });
    if (res.ok) {
      flags.refresh();
    }
    setHandledLoading(null);
    console.log(res);
  };

  const columns = [
    {
      title: "Time",
      key: "time",
      dataIndex: "time",
      render: prettyDate,
      ...sortDate("time"),
    },
    {
      title: "Runner",
      key: "runner",
      render: formatName,
      ...getFilterValues(
        flags.data,
        ["runner", "user", "username"],
        formatName
      ),
      ...sortAlpha(null, formatName),
    },
    {
      title: "Flag",
      key: "flag",
      render: (flag) => (
        <>
          <Text>
            {formatFlagType(flag.type)}
            <br />
          </Text>
          <Text type="secondary">{flag.detail}</Text>
        </>
      ),
      ...getFilterValues(flags.data, "type", ({ type }) =>
        formatFlagType(type)
      ),
    },
    {
      title: "Severity",
      key: "severity",
      dataIndex: "severity",
      render: (v) =>
        (
          <Rate
            defaultValue={v}
            character={({ index }) => index + 1}
            style={{ color: "#000" }}
            disabled
          />
        ) || <NullCell />,
      ...sortNum("severity"),
    },
    {
      title: "Note",
      key: "note",
      dataIndex: "note",
      align: "center",
      render: (note) =>
        note ? (
          <Tooltip title={<BreakLines text={note} />}>
            <PaperClipOutlined color="secondary" />
          </Tooltip>
        ) : (
          <NullCell />
        ),
    },
    {
      title: "Handled",
      key: "handled",
      align: "center",
      render: (flag) =>
        handledLoading === flag.id ? (
          <LoadingOutlined spin />
        ) : flag.handled ? (
          <CheckOutlined
            onClick={(e) => {
              e.stopPropagation();
              markHandled(flag, false);
            }}
          />
        ) : (
          <MinusOutlined
            onClick={(e) => {
              e.stopPropagation();
              markHandled(flag, true);
            }}
          />
        ),
    },
  ];

  return (
    <Table
      onRow={(record) => ({
        onClick: () => {
          history.push(`/flags/detail/${record.id}`);
        },
      })}
      scroll={{ x: "max-content" }}
      pagination={props.pagination || false}
      dataSource={flags.data}
      columns={columns.filter((c) => !excludeCols.includes(c.key))}
      rowKey={(record) => record.id}
      loading={flags.loading}
      size="small"
    />
  );
};

export default FlagsTable;
