import { Col, Row, Typography, Button, Space, Drawer } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  // RangeOrDatePicker,
  useApi,
  apiUtils,
  useDrawer,
} from "antdash";
import { BsStack } from "react-icons/bs";
import Icon from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import flagsApi from "../api/flags";
import FlagsTable from "../components/FlagsTable";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import AddFlag from "../components/forms/AddFlag";
import RangeOrDatePicker from "../components/RangeOrDatePicker";
import useQueryDate from "../tools/useQueryDate";
import useQueryDateRange from "../tools/useQueryDateRange";

const { Title } = Typography;

const Flags = () => {
  const now = new Date();
  const [dates, setDates] = useQueryDateRange([now, now]);

  const breakpoint = useBreakpoint();
  const addFlagDrawer = useDrawer();

  const handledFlags = useApi({
    apiFunc: flagsApi.list,
    defaultParams: {
      sort: ["-id"],
      "filter{handled}": true,
      ...apiUtils.dateFilter(dates[0], dates[1], "time"),
    },
    unpackName: "flags",
    defaultData: [],
    debug: true,
  });

  const unhandledFlags = useApi({
    apiFunc: flagsApi.list,
    defaultParams: {
      sort: ["-id"],
      "filter{handled}": false,
      ...apiUtils.dateFilter(dates[0], dates[1], "time"),
    },
    unpackName: "flags",
    defaultData: [],
    debug: true,
  });

  return (
    <>
      <Drawer
        title="Add Flag"
        width={breakpoint.md ? 500 : "100%"}
        onClose={addFlagDrawer.close}
        visible={addFlagDrawer.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddFlag
          onSuccess={() => {
            unhandledFlags.refresh();
            handledFlags.refresh();
            addFlagDrawer.close();
          }}
        />
      </Drawer>
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col span={5}>
          <Title level={4} style={{ marginBottom: 0 }}>
            Flags
          </Title>
        </Col>
        <Col span={14} align="middle">
          <RangeOrDatePicker
            dates={dates}
            setDates={setDates}
            setParams={(p) => {
              unhandledFlags.setParams(p);
              handledFlags.setParams(p);
            }}
            dateFieldName="time"
          />
        </Col>
        <Col span={5} align="end">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addFlagDrawer.open}
          >
            Add Flag
          </Button>
        </Col>
        {unhandledFlags.data.length > 0 && (
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Link to="/flags/unhandled">
                <Title level={5}>
                  Unhandled ({unhandledFlags.data.length})
                </Title>
              </Link>
              <Link to="/flags/unhandled">View all unhandled</Link>
            </div>
            <FlagsTable flags={unhandledFlags} excludeCols={["handled"]} />
          </Col>
        )}
        <Col span={24}>
          <Title level={5}>Handled ({handledFlags.data.length})</Title>
          <FlagsTable flags={handledFlags} excludeCols={["handled"]} />
        </Col>
      </Row>
    </>
  );
};

export default Flags;
