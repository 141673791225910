import React, { useState } from "react";
import {
  Form,
  Select,
  Drawer,
  Button,
  message,
  Row,
  Col,
  InputNumber,
  Switch,
  Space,
  Typography,
  Divider,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  useAuthStorage,
  UpdatePassword,
  useDrawer,
  useApi,
  authApi,
  useApiList,
} from "antdash";
import trackingGroupsApi from "../api/trackingGroups";
import Splash from "../components/Splash";

const { Text } = Typography;

export const BET_INPUT_OPTIONS = Object.freeze({
  RACECARD: "Racecard",
  MANUAL: "Manual",
});

export const RACECARD_EVENT_TYPE_OPTIONS = Object.freeze({
  HORSES_GB_IE: "Horses (GB/IE)",
  HORSES_AU: "Horses (AU)",
  HORSES_NZ: "Horses (NZ)",
  HORSES_US: "Horses (USA)",
  HORSES: "Horses",
  DOGS: "Dogs",
  SOCCER: "Soccer",
  GAELIC_GAMES: "Gaelic Games",
  GOLF: "Golf",
  RUGBY_LEAGUE: "Rugby League",
  RUGBY_UNION: "Rugby Union",
  TENNIS: "Tennis",
  BOXING: "Boxing",
  BASKETBALL: "Basketball",
  CRICKET: "Cricket",
  ICE_HOCKEY: "Ice Hockey",
  MOTOR_SPORT: "Motor Sport",
  ESPORTS: "Esports",
  SPECIAL_BETS: "Special Bets",
  CYCLING: "Cycling",
  AUSTRALIAN_RULES: "Australian Rules",
  DARTS: "Darts",
  MIXED_MARTIAL_ARTS: "Mixed Martial Arts",
  POLITICS: "Politics",
  SNOOKER: "Snooker",
  BASEBALL: "Baseball",
  AMERICAN_FOOTBALL: "American Football",
  NETBALL: "Netball",
});

const Settings = () => {
  const breakpoint = useBreakpoint();

  const options = useApi({ apiFunc: authApi.settingsOptions, debug: true });
  const trackingGroups = useApiList(trackingGroupsApi, "tracking_groups");

  const [loadingField, setLoadingField] = useState(null);
  const [form] = Form.useForm();
  const { user, updateUserSettings } = useAuthStorage();

  const onError = () => {
    message.error("Error updating settings.");
    form.resetFields();
  };

  const onFieldChange = (fieldName, value) => {
    updateUserSettings(fieldName, value, onError, setLoadingField);
  };

  console.log(user.settings);
  console.log(options.data?.actions.PUT.default_selection_expiry_base.choices);

  const passwordDrawer = useDrawer();

  if (options.data === null) {
    return <Splash />;
  }

  return (
    <>
      <Drawer
        title="Update Password"
        width={breakpoint.md ? 500 : "100%"}
        onClose={passwordDrawer.close}
        visible={passwordDrawer.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <UpdatePassword onSuccess={passwordDrawer.close} />
      </Drawer>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          display_currency: user.settings.display_currency,
          default_event_type: user.settings.default_event_type,
          default_bet_input: user.settings.default_bet_input,
          default_tracking_groups: user.settings.default_tracking_groups.map(
            (x) => x.id
          ),
          default_selection_expiry_base:
            user.settings.default_selection_expiry_base,
          default_selection_expiry_offset:
            user.settings.default_selection_expiry_offset,
        }}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Divider orientation="center">General</Divider>
        <Form.Item name="display_currency" label="Display Currency">
          <Select
            loading={loadingField === "display_currency"}
            onChange={(value) => onFieldChange("display_currency", value)}
            options={options.data?.actions.PUT.display_currency.choices}
          />
        </Form.Item>
        <Button
          type="secondary"
          onClick={passwordDrawer.open}
          icon={<LockOutlined />}
        >
          Update Password
        </Button>
        <Divider orientation="center">Selection input settings</Divider>
        <Form.Item name="default_bet_input" label="Default bet input tab">
          <Select
            loading={loadingField === "default_bet_input"}
            onChange={(value) => onFieldChange("default_bet_input", value)}
          >
            {Object.values(BET_INPUT_OPTIONS).map((x) => (
              <Select.Option value={x} key={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="default_event_type" label="Default event type">
          <Select
            loading={loadingField === "default_event_type"}
            onChange={(value) => onFieldChange("default_event_type", value)}
          >
            {Object.values(RACECARD_EVENT_TYPE_OPTIONS).map((x) => (
              <Select.Option value={x} key={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Default Selection Limitations">
          <Space direction="vertical">
            <Space>
              Allow each way
              <Switch
                defaultChecked={user.settings.default_allow_each_way}
                size="small"
                onChange={(value) =>
                  onFieldChange("default_allow_each_way", value)
                }
              />
            </Space>
            <Space>
              Each way only
              <Switch
                defaultChecked={user.settings.default_each_way_only}
                size="small"
                onChange={(value) =>
                  onFieldChange("default_each_way_only", value)
                }
              />
            </Space>
            <Space>
              Singles only
              <Switch
                defaultChecked={user.settings.default_singles_only}
                size="small"
                onChange={(value) =>
                  onFieldChange("default_singles_only", value)
                }
              />
            </Space>
            <Space>
              Multiples only
              <Switch
                defaultChecked={user.settings.default_multiples_only}
                size="small"
                onChange={(value) =>
                  onFieldChange("default_multiples_only", value)
                }
              />
            </Space>
          </Space>
        </Form.Item>
        <Row gutter={[16, 16]} align="middle">
          <Col span={16}>
            <Form.Item
              name="default_selection_expiry_base"
              label="Default selection expiry"
            >
              <Select
                loading={loadingField === "default_selection_expiry_base"}
                onChange={(value) =>
                  onFieldChange("default_selection_expiry_base", value)
                }
                options={options.data?.actions.PUT.default_selection_expiry_base.choices.map(
                  (x) => ({ value: x.value, label: x.display_name })
                )}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="default_selection_expiry_offset" label="Minutes">
              <InputNumber
                min={0}
                step={1}
                onChange={(value) =>
                  onFieldChange("default_selection_expiry_offset", value)
                }
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="default_tracking_groups"
          label="Default Tracking Groups"
        >
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Add Tracking Groups..."
            optionFilterProp="children"
            onChange={(value, e) => {
              console.log(value, e);
              onFieldChange("default_tracking_groups", value);
            }}
          >
            {trackingGroups.list.map(({ name, id }) => (
              <Select.Option value={id} key={name}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

export default Settings;
