import { useContext, useState } from "react";
import {
  Form,
  Col,
  Row,
  Input,
  Button,
  Result,
  Typography,
  Select,
} from "antd";
import {
  useSubmit,
  getCurrencySymbol,
  InputMoney,
  useAuthStorage,
} from "antdash";
import { CheckOutlined } from "@ant-design/icons";
import { ODDS_FORMATS, ODDS_FORMAT_OPTIONS } from "betui";
import AppContext from "../../context/AppContext";
import runnersApi from "../../api/runners";

const { Paragraph } = Typography;

const NewRunner = ({ closeDrawer, refresh }) => {
  const { user } = useAuthStorage();
  const defaultCurrency = user.settings.display_currency;
  const { config } = useContext(AppContext);
  const currencyOptions = Object.keys(config.CURRENCY_SYMBOLS).map((k) => ({
    label: k,
    value: k,
  }));

  const [createdData, setCreatedData] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(
    getCurrencySymbol(defaultCurrency)
  );
  const submit = (formData) => runnersApi.create(formData);

  const success = (res) => {
    setCreatedData(res.data);
    refresh();
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  if (createdData) {
    const { user } = createdData.runner;
    return (
      <Result
        status="success"
        title="Runner Created"
        subTitle={
          <>
            {createdData.email_sent && (
              <Paragraph type="secondary" style={{ marginTop: "1em" }}>
                Login details have been emailed to <b>{user.email}</b>
              </Paragraph>
            )}
            <Paragraph
              type="secondary"
              copyable={{
                text: `Username: ${user.username}, Password: ${createdData.auto_password}`,
                tooltips: ["copy details", "copied"],
              }}
            >
              Username: {user.username}, Password: {createdData.auto_password}
            </Paragraph>
          </>
        }
        extra={[
          <Button
            type="primary"
            key="close"
            onClick={() => {
              closeDrawer();
              setCreatedData(null);
            }}
          >
            Done
          </Button>,
          <Button
            key="another"
            onClick={() => {
              setCreatedData(null);
            }}
          >
            Add Another
          </Button>,
        ]}
      />
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onFinish}
      initialValues={{
        float: 0,
        float_currency: defaultCurrency,
        odds_format: ODDS_FORMATS.FRACTIONAL.value,
        target_payout: 1500,
      }}
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: "Please enter a name." }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please enter a username" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="email" label="Email">
            <Input type="email" />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            name="float"
            label="Initial Float"
            rules={[
              { required: true, message: "Please provide initial float." },
            ]}
          >
            <InputMoney symbol={currencySymbol} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="float_currency"
            label="Currency"
            rules={[{ required: true, message: "Please select a currency." }]}
          >
            <Select
              onChange={(v) => setCurrencySymbol(getCurrencySymbol(v))}
              options={currencyOptions}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="target_payout"
            extra={`Level of winnings targeted by default stake. The runner can alter this value themselves if required.`}
            label="Betting Level"
            rules={[
              { required: true, message: "Please enter a betting level." },
            ]}
          >
            <InputMoney symbol={currencySymbol} />
          </Form.Item>
        </Col>
        <Col span={24} align="middle">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Create Runner
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewRunner;
