import React from "react";
import { Row, Col, Table, Typography, Drawer, Button } from "antd";
import { useParams } from "react-router";
import { Money, useApi, useApiList, useDrawer } from "antdash";
import selectionsApi from "../api/selections";
import AddBet from "../components/AddBet";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { betType, formatOdds } from "betui";

import ResultEditable from "../components/result/ResultEditable";
import betsApi from "../api/bets";
import SelectionCard from "../components/SelectionCard";
import AppBreadcrumb from "../components/AppBreadcrumb";
import { routes } from "../../App";

const { Title, Text } = Typography;

const SelectionDetail = () => {
  const { id } = useParams();
  const breakpoint = useBreakpoint();
  const addBetDrawer = useDrawer();
  const selection = useApi({
    apiFunc: selectionsApi.get,
    unpackName: "selection",
    defaultParams: id,
  });
  const bets = useApiList(betsApi, "bets", {
    "filter{bet_selections.selection.id}": id,
  });

  const columns = [
    {
      title: "Runner",
      dataIndex: ["runner", "user", "first_name"],
    },
    {
      title: "Bet",
      render: (bet) => (
        <>
          <Text type="secondary">{betType(bet)}</Text>
          <div>
            {bet.bet_selections.map(({ id, selection, odds }) => (
              <ResultEditable
                key={id + selection.result}
                selection={selection}
                settle={(result) => selectionsApi.settle(selection.id, result)}
                onResultChange={bets.refresh}
                title={`${selection.event}: ${selection.runner} @ ${formatOdds(
                  odds
                )}`}
              />
            ))}
          </div>
        </>
      ),
    },
    {
      title: "Stake",
      render: (bet) => (
        <Money amount={bet.stake} currency={bet.stake_currency} />
      ),
      align: "center",
    },
    {
      title: "Payout",
      render: (bet) => (
        <Money amount={bet.expected_payout} currency={bet.stake_currency} />
      ),
      align: "center",
    },
  ];

  return (
    <>
      {/* <Drawer
        title="Add Bet"
        width={breakpoint.md ? 500 : "100%"}
        onClose={addBetDrawer.close}
        visible={addBetDrawer.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <AddBet
          selection={selection.data}
          closeDrawer={addBetDrawer.close}
          refresh={selection.refresh}
        />
      </Drawer> */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <AppBreadcrumb
            routes={[routes.SELECTIONS, routes.SELECTION_DETAIL]}
          />
        </Col>
        <Col span={24} xl={12}>
          <SelectionCard
            selection={selection.data}
            loading={selection.loading}
          />
        </Col>
        <Col span={24} xl={12}>
          <Row justify="space-between">
            <Col>
              <Title level={4}>Bets</Title>
            </Col>
            {/* <Col>
              <Button onClick={addBetDrawer.open}>Add Bet</Button>
            </Col> */}
          </Row>
          <Table
            scroll={{ x: "max-content" }}
            pagination={false}
            dataSource={bets.list}
            columns={columns}
            loading={bets.loading}
            rowKey={(record) => record.id}
            footer={() => `Total bets: ${bets.list.length}`}
            size="small"
            locale={{ emptyText: "No bets placed with this selection." }}
          />
          {/* <div>Selection: {JSON.stringify(selection.data)}</div> */}
        </Col>
      </Row>
    </>
  );
};

export default SelectionDetail;
