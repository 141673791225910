import { useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Button,
  Select,
  Space,
  Typography,
  Divider,
} from "antd";
import { useSubmit, getCurrencySymbol, InputMoney, useApiList } from "antdash";
import { DeleteOutlined } from "@ant-design/icons";
import {
  BET_TYPES,
  convertIfFractional,
  InputOdds,
  BET_TYPE_OPTIONS,
} from "betui";
import runnersApi from "../api/runners";
import betsApi from "../api/bets";
import AddSelection from "./AddSelection";

const { Option } = Select;
const { Text } = Typography;

const selectionsPadded = (selections, length) => {
  if (length === null) {
    return selections;
  }
  if (selections.length > length) {
    return selections.slice(0, length);
  }
  return selections.concat(Array(length - selections.length).fill(null));
};

const AddBet = ({ selection, closeDrawer, refresh }) => {
  const [eachWay, setEachWay] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("€");
  const [numSelections, setNumSelections] = useState(1);
  const [selections, setSelections] = useState([selection]);
  const [fieldData, setFieldData] = useState({});

  const updateSelections = (item, add) => {
    console.log(item, add);
    const key = item.market_id;
    console.log("KEY:", key);
    const nextSelections = add
      ? selections.map((i) => i.market_id).includes(key)
        ? selections.map((i) => (i.market_id === key ? item : i))
        : [...selections, item]
      : selections.filter((i) => i.market_id !== key);

    console.log(nextSelections);

    setSelections(nextSelections.length > 0 ? nextSelections : [selection]);
  };

  const submit = (formData) =>
    betsApi.create({
      ...formData,
      each_way: eachWay,
      selections: [
        { selection: selection.id, odds: convertIfFractional(formData.odds) },
      ],
    });

  const runners = useApiList(runnersApi, "runners");

  const success = (res) => {
    refresh();
    closeDrawer();
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  const ewEnabled = !selections.map((s) => s.ew_divisor).includes(null);

  useEffect(() => {
    if (!ewEnabled) {
      setEachWay(false);
    }
  }, [selections]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onFinish}
      initialValues={{
        bet_type: BET_TYPES.SINGLE.value,
        ...fieldData,
      }}
      onValuesChange={(_, data) => setFieldData(data)}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Row justify="center" align="middle" gutter={[16, 16]}>
            <Col>
              <Form.Item
                name="bet_type"
                rules={[{ required: true }]}
                style={{ width: 150, marginBottom: 0 }}
              >
                <Select onChange={(_, { num }) => setNumSelections(num)}>
                  {BET_TYPE_OPTIONS.map((x) => (
                    <Select.Option key={x.value} value={x.value} num={x.num}>
                      {x.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} key={selections.length}>
          <Divider />
          {selectionsPadded(
            selections,
            fieldData.bet_type === BET_TYPES.ACCUMULATOR.value
              ? selections.length + 1
              : numSelections
          ).map((item, index) => (
            <Row key={index} gutter={[8, 8]} align="top">
              {item === null ? (
                <Col span={24}>
                  <Form.Item
                    name={`selection_blank_${index}`}
                    rules={[
                      {
                        required: true,
                        message: `Selection ${index + 1} required.`,
                      },
                    ]}
                    style={{ marginBottom: 8 }}
                  >
                    <AddSelection
                      text={
                        fieldData.bet_type === BET_TYPES.ACCUMULATOR.value
                          ? "Add Selection"
                          : `Selection ${index + 1}`
                      }
                      updateSelections={updateSelections}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <>
                  <Col span={14}>
                    <div
                      style={{
                        ...styles.selectionCol,
                        paddingTop: !index ? 40 : 10,
                        paddingBottom: 8,
                      }}
                    >
                      <Space>
                        <Text type="secondary">
                          {item.event}: {item.runner}{" "}
                        </Text>
                      </Space>
                    </div>
                  </Col>
                  <Col span={9}>
                    <div style={styles.selectionCol}>
                      <Form.Item
                        label={!index && "Odds"}
                        name={`odds_${item.key}`}
                        rules={[
                          {
                            required: true,
                            message: "Odds required.",
                          },
                        ]}
                        style={{ marginBottom: 8, width: "100%" }}
                      >
                        <InputOdds />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={1}>
                    <div
                      style={{
                        ...styles.selectionCol,
                        paddingTop: !index ? 40 : 10,
                        paddingBottom: 8,
                        color: index ? "red" : "lightgray",
                      }}
                    >
                      <DeleteOutlined
                        onClick={() => index && updateSelections(item, false)}
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          ))}
        </Col>
        <Col span={24}>
          <Divider />
          <Form.Item
            name="runner_id"
            label="Runner"
            rules={[{ required: true, message: "Please select a runner." }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              onChange={(_, { currency }) =>
                setCurrencySymbol(getCurrencySymbol(currency))
              }
              loading={runners.loading}
            >
              {runners.list.map(({ id, float_currency, user }) => (
                <Option value={id} currency={float_currency} key={id}>
                  {user.first_name} {user.last_name} ({float_currency})
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="unit_stake"
            label="Stake"
            rules={[{ required: true, message: "Please enter a stake." }]}
          >
            <InputMoney symbol={currencySymbol} />
          </Form.Item>
        </Col>
        <Col span={24} align="middle">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Add Bet
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddBet;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#c0c0c0",
    alignItems: "center",
    padding: 16,
    height: "100%",
  },
  selectionCol: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
};
