import {
  Card,
  Skeleton,
  Row,
  Col,
  Space,
  Typography,
  Button,
  Drawer,
  Tag,
} from "antd";
import { SettingOutlined, SwapOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { MoneyTitle, StatisticRedGreen, useDrawer } from "antdash";
import TransferForm from "./forms/TransferForm";
import UpdateRunner from "./forms/UpdateRunner";

const { Title } = Typography;

const RunnerCard = ({ runner, setRunner, onDepositWithdraw, loading }) => {
  const breakpoint = useBreakpoint();

  const transferDrawer = useDrawer();
  const settingsDrawer = useDrawer();

  const onTransferSuccess = (res) => {
    onDepositWithdraw(res);
    transferDrawer.close();
  };

  return (
    <>
      <Drawer
        title="Runner Settings"
        width={breakpoint.md ? "45%" : "100%"}
        onClose={settingsDrawer.close}
        visible={settingsDrawer.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <UpdateRunner
          runner={runner}
          setRunner={setRunner}
          closeDrawer={settingsDrawer.close}
        />
      </Drawer>
      <Card style={{ zIndex: 1, overflow: "hidden" }}>
        <Skeleton loading={loading} active>
          {runner && (
            <>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row justify="space-between">
                    <Col>
                      <Space>
                        <Title level={4} style={{ marginBottom: 0 }}>
                          {runner.user.first_name} {runner.user.last_name}
                        </Title>
                        {!runner.user.is_active && (
                          <Tag color="red">Deactivated</Tag>
                        )}
                      </Space>
                    </Col>
                    <Col>
                      <Button
                        icon={<SettingOutlined />}
                        onClick={settingsDrawer.open}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <MoneyTitle
                        label="Balance"
                        amount={runner.float}
                        currency={runner.float_currency}
                      />
                    </Col>
                    <Col>
                      <Space>
                        <Button
                          onClick={transferDrawer.open}
                          icon={<SwapOutlined />}
                        >
                          {breakpoint.xs ? "" : "TRANSFER"}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <MoneyTitle
                        label="Staked"
                        level={5}
                        amount={runner.total_staked}
                        currency={runner.float_currency}
                      />
                      <MoneyTitle
                        label="Won"
                        level={5}
                        amount={runner.total_won}
                        currency={runner.float_currency}
                      />
                    </Col>
                    <Col>
                      <StatisticRedGreen
                        value={
                          ((runner.total_won - runner.total_staked) /
                            runner.total_staked) *
                          100
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Drawer
                title={`Transfer: ${runner.user.first_name} ${runner.user.last_name} (${runner.float_currency})`}
                placement="top"
                onClose={transferDrawer.close}
                visible={transferDrawer.visible}
                getContainer={false}
                style={{ position: "absolute" }}
                width={"100%"}
                height={"100%"}
              >
                {runner && (
                  <TransferForm
                    runner={runner}
                    onTransferSuccess={onTransferSuccess}
                  />
                )}
              </Drawer>
            </>
          )}
        </Skeleton>
      </Card>
    </>
  );
};

export default RunnerCard;
