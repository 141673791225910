import { Card, Row, Col, Typography, Space, Button, Breadcrumb } from "antd";
import { LeftCircleFilled, LeftOutlined } from "@ant-design/icons";
import { useApi } from "antdash";
import React from "react";
import { useParams } from "react-router-dom";
import flagsApi from "../api/flags";
import Splash from "../components/Splash";
import { routes } from "../../App";
import AppBreadcrumb from "../components/AppBreadcrumb";
import Flag from "../components/Flag";

const FlagDetail = () => {
  const { id } = useParams();

  const flag = useApi({
    apiFunc: () => flagsApi.get(id),
    unpackName: "flag",
    defaultData: null,
    debug: true,
  });

  return (
    <Row gutter={[16, 16]} align="middle" justify="space-between">
      <Col>
        <AppBreadcrumb routes={[routes.FLAGS, routes.FLAG_DETAIL]} />
      </Col>
      <Col></Col>
      <Col span={24}>
        {flag.data !== null ? (
          <Flag flag={flag.data} setFlag={flag.setData} />
        ) : (
          <Splash />
        )}
      </Col>
    </Row>
  );
};

export default FlagDetail;
