import React from "react";
import { Typography, Tag, Divider } from "antd";
import { useAuthStorage } from "antdash";
import SyntaxHighlighter from "react-syntax-highlighter";
// import { tomorrowNightBlue as theme } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { nightOwl as theme } from "react-syntax-highlighter/dist/esm/styles/hljs";

const { Text, Title, Paragraph } = Typography;

const CodeTag = (...props) => (
  <Tag {...props} style={{ ...props.style, marginRight: 0 }} />
);

const Api = () => {
  const { user } = useAuthStorage();

  const apiUrl = `${window.location.origin}/api/staff`;
  const token = user.token;

  const codeString = `import requests

BASE_URL = "${apiUrl}"
TOKEN = "${token}"
HEADERS = {"Authorization": f"Token {TOKEN}"}

res = requests.get(f"{BASE_URL}/ping", headers=HEADERS)

print(res.json()) # {"runner_says": "pong"}`;
  return (
    <>
      <Title level={4}>Runner API</Title>
      <Paragraph>
        The Runner API can be used to manage any aspect of the system. In fact,
        this dashboard uses the API for all of its interaction with the backend.
        Anything that can be controlled from within this dashboard, and indeed
        much more, can be automated with software written in a programming
        language of your choice.
      </Paragraph>
      <Paragraph>
        Some potential uses:
        <ul>
          <li>
            Automate selection input, update and settlement
            <li>
              Combine your models with live data sources to automatically add
              selections as soon as they become favourable.
            </li>
          </li>
          <li>
            Apply arbitrary management logic
            <li>
              Impose any additional logic your betting strategy requires. Cancel
              selections once enough money has been staked, or after a set time
              limit or every five and a half minutes on Wednesdays... the
              possibilities are endless!
            </li>
          </li>
          <li>Add support for alternative event feeds</li>
          <li>Automate monitoring</li>
          <li>Generate reports</li>
        </ul>
      </Paragraph>

      <Title level={4}>Your API Key:</Title>
      <Text id="api-key" copyable>
        {user.token}
      </Text>
      <Title level={4}>Quickstart</Title>

      <SyntaxHighlighter
        language="python"
        style={theme}
        customStyle={{ padding: "2em" }}
      >
        {codeString}
      </SyntaxHighlighter>

      <Paragraph>The API can be accessed at at {apiUrl}</Paragraph>

      <Paragraph>
        When sending a request to the API, your API key must be included in the
        request's{" "}
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization"
          target="_blank"
        >
          Authorization
        </a>{" "}
        HTTP header. The value you provide should be your API key prefixed by
        the word "Token" and a space.
      </Paragraph>

      <SyntaxHighlighter
        language="python"
        style={theme}
        customStyle={{ padding: "2em" }}
      >
        {`# Include in your request's Authorization header:
"Token ${token}"`}
      </SyntaxHighlighter>
      <Divider />
      <Title level={4}>🚧 Full Documentation Coming Soon...</Title>
      <Paragraph>
        While the API is in full working order, the documentation is still a
        work in progress. Please feel free to{" "}
        <a href="mailto:info@runner-app.com">get in touch</a> with any queries.
      </Paragraph>
    </>
  );
};

export default Api;
