import { useState } from "react";
import {
  Table,
  Col,
  Row,
  Space,
  Typography,
  Button,
  Tooltip,
  Card,
  Divider,
  Tag,
} from "antd";
import { apiUtils, usePages, RangeOrDatePicker, useAuthStorage } from "antdash";
import { formatOdds, SettlementTypeIndicator } from "betui";
import { useHistory } from "react-router-dom";
import selectionsApi from "../api/selections";
import AggregatedCurrencyMoney from "../components/AggregatedCurrencyMoney";
import ResultEditable from "../components/result/ResultEditable";
import {
  SyncOutlined,
  PlusOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { formatDistanceStrict } from "date-fns";
const { Text, Title } = Typography;

const Selections = () => {
  const today = new Date();
  const history = useHistory();

  const [betslip, setBetslip] = useState(null);
  const [expireLoading, setExpireLoading] = useState(null);

  const updateBetslip = (selection, add = true) => {
    if (add) {
      setBetslip((current) =>
        current.map((i) => i.id).includes(selection.id)
          ? current
          : [...current, selection]
      );
    } else {
      setBetslip((current) => current.filter((x) => x.id !== selection.id));
    }
  };

  const { user } = useAuthStorage();

  const selectionsList = usePages({
    apiFunc: selectionsApi.list,
    unpackName: "selections",
    defaultParams: apiUtils.dateFilter(today, today),
    defaultPerPage: 20,
    debug: true,
  });

  const columns = [
    {
      title: "Event",
      key: "event",
      render: (selection) => (
        <Space>
          <SettlementTypeIndicator selection={selection} />
          {selection.event}
        </Space>
      ),
      width: "25%",
    },
    {
      title: "Runner",
      key: "runner",
      dataIndex: "runner",
    },

    {
      title: "Odds",
      key: "odds",
      dataIndex: "odds",
      render: formatOdds,
      align: "end",
    },
    {
      title: "Total Staked",
      key: "staked",
      render: (selection) => (
        <AggregatedCurrencyMoney
          detail={selection.total_staked}
          displayCurrency={user.settings.display_currency}
        />
      ),
      align: "end",
    },
    {
      title: "Expires",
      render: ({ id, expired, expires }) =>
        expired ? (
          <Tag style={{ margin: 0 }}>
            {formatDistanceStrict(new Date(expires), today, {
              addSuffix: true,
            })}
          </Tag>
        ) : (
          <Tooltip title="Expire now">
            <Tag
              icon={expireLoading === id && <LoadingOutlined />}
              style={{ cursor: "pointer", margin: 0 }}
              color="green"
              onClick={async (e) => {
                e.stopPropagation();
                setExpireLoading(id);
                const res = await selectionsApi.update(id, {
                  expires: new Date(),
                });
                console.log(res);
                if (res.ok) {
                  selectionsList.refresh();
                }
                setExpireLoading(null);
              }}
            >
              {formatDistanceStrict(new Date(expires), today)}
            </Tag>
          </Tooltip>
        ),
      align: "center",
    },
    {
      title: "Result",
      key: "result",
      render: (selection) => (
        <ResultEditable
          selection={selection}
          settle={(result) => selectionsApi.settle(selection.id, result)}
          onResultChange={(res) => {
            console.log(res);
            selectionsList.setData((current) =>
              current.map((row) => (row.id === selection.id ? res.data : row))
            );
          }}
          title={selection.result}
          titleShort={selection.result}
        />
      ),
      align: "center",
    },
    {
      title: "Payout",
      key: "payout",
      render: (selection) => (
        <AggregatedCurrencyMoney
          detail={selection.total_expected}
          displayCurrency={user.settings.display_currency}
          key={selection.result}
        />
      ),
      align: "end",
    },
  ];

  return (
    <Row gutter={[16, 16]} justify="space-between">
      <Col>
        <Title level={4} style={{ marginBottom: 0 }}>
          Selections
        </Title>
      </Col>
      <Col>
        <RangeOrDatePicker setParams={selectionsList.setParams} />
      </Col>
      <Col>
        <Space>
          {/* {betslip === null ? (
            <Tooltip title="Add Bet" placement="left">
              <Button onClick={() => setBetslip([])} icon={<PlusOutlined />} />
            </Tooltip>
          ) : (
            <Tooltip title="Close Betslip" placement="left">
              <Button
                onClick={() => setBetslip(null)}
                icon={<CloseOutlined />}
              />
            </Tooltip>
          )} */}
          <Button onClick={selectionsList.refresh} icon={<SyncOutlined />} />
        </Space>
      </Col>
      {betslip !== null && (
        <Col span={24}>
          <Card style={{ textAlign: "center" }}>
            <div>
              <Text strong>Betslip</Text>
            </div>
            {betslip.length === 0 ? (
              <Space style={{ paddingTop: 15, paddingBottom: 15 }}>
                <Text type="secondary">
                  Use the <PlusOutlined /> to add selections to the betslip.
                </Text>
              </Space>
            ) : (
              <Space direction="vertical" style={{ width: "100%" }}>
                {betslip.map((s, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text>{s.runner}</Text>
                    <CloseOutlined onClick={() => updateBetslip(s, false)} />
                  </div>
                ))}
                <Button type="primary" size="small">
                  Create Bet
                </Button>
              </Space>
            )}
            <Divider />
            <Text>
              Bets added through the admin page are not subject to the same
              limitations imposed by the Runner Agent app.
            </Text>
          </Card>
        </Col>
      )}
      <Col span={24}>
        <Table
          onRow={(record) => ({
            onClick: () =>
              betslip === null
                ? history.push(`/selections/${record.id}`)
                : updateBetslip(record),
          })}
          scroll={{ x: "max-content" }}
          pagination={{ ...selectionsList.paginationProps }}
          dataSource={selectionsList.data}
          columns={columns}
          loading={selectionsList.loading}
          rowKey={(record) => record.id}
          footer={() => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space>
                <SettlementTypeIndicator
                  selection={{ settle_manually: false }}
                />
                <Text type="secondary">Settling Automatically</Text>
                <SettlementTypeIndicator
                  selection={{ settle_manually: true }}
                />
                <Text type="secondary">Settle Manually</Text>
              </Space>
              <Text type="secondary">
                Total selections: {selectionsList.totalResults}
              </Text>
            </div>
          )}
          size={"small"}
          locale={{ emptyText: "No selections for this time range." }}
        />
      </Col>
    </Row>
  );
};

export default Selections;
