import { useState } from "react";
import {
  Form,
  Col,
  Row,
  Select,
  Button,
  message,
  Divider,
  Space,
  Typography,
} from "antd";
import { useSubmit, InputMoney, getCurrencySymbol } from "antdash";
import { ODDS_FORMAT_OPTIONS } from "betui";
import usersApi from "../../api/users";
import runnersApi from "../../api/runners";

const { Text } = Typography;

const UpdateRunner = ({ runner, setRunner, closeDrawer }) => {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const currencySymbol = getCurrencySymbol(runner.float_currency);

  const submit = (formData) => runnersApi.update(runner.id, formData);

  const success = (res) => {
    console.log(res);
    message.success("Updated runner settings.");
    setRunner(res.data.runner);
    closeDrawer();
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  const toggleRunnerActive = async () => {
    const toggled = !runner.user.is_active;
    const prefix = toggled ? "" : "de";
    const res = await usersApi.setActive(runner.user.id, toggled);
    if (res.ok) {
      setRunner((runner) => ({ ...runner, user: res.data.user }));
      message.success(`Runner ${prefix}activated.`);
    } else {
      message.error(`Error ${prefix}activating runner.`);
    }
  };

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const resetPassword = async () => {
    setResetPasswordLoading(true);
    const res = await usersApi.resetPassword(runner.user.id);
    setResetPasswordLoading(false);
    if (res.ok) {
      setNewPassword(res.data.auto_password);
      message.success(`Password reset.`);
    } else {
      setNewPassword(null);
      message.error(`Error resetting password.`);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        requiredMark="optional"
        initialValues={{
          target_payout: runner.target_payout,
          odds_format: runner.odds_format,
        }}
        onFinish={onFinish}
        onFieldsChange={() => setEnableSubmit(true)}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="target_payout"
              label="Target Payout"
              rules={[
                { required: true, message: "Please enter a target payout." },
              ]}
            >
              <InputMoney symbol={currencySymbol} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="odds_format"
              label="Odds Format"
              rules={[{ required: true }]}
            >
              <Select options={ODDS_FORMAT_OPTIONS} />
            </Form.Item>
          </Col>
          <Col span={24} align="middle">
            <Form.Item>
              <Button
                htmlType="submit"
                loading={submitting}
                disabled={!enableSubmit}
              >
                Save Changes
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Space direction="vertical">
        <Button
          type="outline"
          loading={resetPasswordLoading}
          onClick={resetPassword}
        >
          Reset Password
        </Button>
        <Space>
          {newPassword && (
            <>
              <Text>New Password: </Text>
              <Text copyable>{newPassword}</Text>
            </>
          )}
        </Space>
        {runner.user.is_active ? (
          <Button type="outline" onClick={toggleRunnerActive} danger>
            Deactivate Runner
          </Button>
        ) : (
          <Button type="outline" onClick={toggleRunnerActive}>
            Activate Runner
          </Button>
        )}
      </Space>
    </>
  );
};

export default UpdateRunner;
