import { client } from "antdash";

const list = (data = {}) => client.get("selections", data);

const get = (id) => client.get(`selections/${id}`);

const update = (id, data) => client.patch(`selections/${id}`, data);

const search = (query) => client.get("selection_search", { query });

const create = (data) => client.post("selections", data);

const settle = (selection_id, result) =>
  client.post("settle_selection_manually", { selection_id, result });

const nextSettlement = () => client.get("get_next_settlement");

const sendRandomBet = () => client.get("send_random_bet");

const selectionsApi = {
  list,
  get,
  update,
  search,
  create,
  settle,
  nextSettlement,
  sendRandomBet,
};

export default selectionsApi;
