import {
  Drawer,
  Button,
  Table,
  Row,
  Col,
  Typography,
  Tag,
  Space,
  Modal,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { PlusOutlined } from "@ant-design/icons";
import { useApi, useDrawer, useModal } from "antdash";
import NewStaffMember from "../components/NewStaffMember";
import staffApi from "../api/staff";
import StaffModal from "../components/StaffModal";
import { BsDashLg, BsCheckLg, BsPlusLg } from "react-icons/bs";
import { useState } from "react";
import { Link } from "react-router-dom";
import NewStaffRunner from "../components/forms/NewStaffRunner";

const { Title, Text } = Typography;

const Staff = () => {
  const breakpoint = useBreakpoint();
  const staff = useApi({
    apiFunc: staffApi.list,
    unpackName: "users",
    defaultData: [],
    defaultParams: { "filter{is_active}": true },
    debug: true,
  });
  const newStaffDrawer = useDrawer();
  const modal = useModal();

  const [makeStaffRunner, setMakeStaffRunner] = useState(null);

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (user) => (
        <Space>
          <Text>
            {user.first_name} {user.last_name}
          </Text>
          {!user.is_active && <Tag color="red">Deactivated</Tag>}
        </Space>
      ),
    },
    {
      title: "Runner",
      key: "runner",
      render: (user) =>
        user.runner ? (
          <Link to={`/runners/${user.runner}`}>{user.username}</Link>
        ) : (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setMakeStaffRunner(user);
            }}
            size="small"
            type="link"
          >
            <BsPlusLg color="grey" />
          </Button>
        ),
    },
    {
      title: "Superuser",
      key: "super",
      dataIndex: "is_superuser",
      render: (x) => (x ? <BsCheckLg /> : <BsDashLg color="grey" />),
    },
  ];

  return (
    <>
      <Modal
        visible={makeStaffRunner != null}
        centered
        footer={null}
        onCancel={() => setMakeStaffRunner(null)}
      >
        <NewStaffRunner
          user={makeStaffRunner}
          onSuccess={() => {
            staff.refresh();
            setMakeStaffRunner(null);
          }}
        />
      </Modal>
      <StaffModal
        visible={modal.visible}
        user={modal.data}
        setUser={modal.setData}
        onAction={() => staff.refresh()}
        close={modal.close}
      />
      <Drawer
        title="Add Staff User"
        width={breakpoint.md ? 500 : "100%"}
        onClose={newStaffDrawer.close}
        visible={newStaffDrawer.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <NewStaffMember
          closeDrawer={newStaffDrawer.close}
          refresh={staff.refresh}
        />
      </Drawer>
      <Row gutter={[16, 16]} align="middle" justify="space-between">
        <Col>
          <Title level={4} style={{ marginBottom: 0 }}>
            Staff
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={newStaffDrawer.open}
            icon={<PlusOutlined />}
          >
            Add Staff User
          </Button>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: "max-content" }}
            pagination={false}
            dataSource={staff.data}
            columns={columns}
            rowKey={(record) => record.id}
            loading={staff.loading}
            size={breakpoint.md ? "large" : "small"}
            onRow={(record) => ({
              onClick: () => {
                modal.setData(record);
                modal.open();
              },
            })}
            footer={() => {
              const active = staff.params["filter{is_active}"];
              return (
                <Button
                  type="link"
                  size="small"
                  onClick={() =>
                    staff.setParams((c) => ({
                      "filter{is_active}": !c["filter{is_active}"],
                    }))
                  }
                >
                  Show {active ? "Deactivated" : "Activated"}
                </Button>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Staff;
