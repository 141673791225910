import { Typography } from "antd";
import { format, parseISO } from "date-fns";
import { betType, formatOdds } from "betui";
import { Money } from "antdash";

const { Text } = Typography;

const BetDetails = ({ bet }) => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Text strong>{betType(bet)}</Text>
        <Text type="secondary">
          <br />
          {format(parseISO(bet.created), "h:mm a PPPP")}
        </Text>
      </div>
      <Text strong>
        Selections:
        <br />
      </Text>
      {bet.bet_selections.map((bs, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
          key={index}
        >
          <div>
            <Text>
              <Text strong>{index + 1}.</Text> {bs.selection.event}:{" "}
              {bs.selection.runner}{" "}
            </Text>
          </div>
          <div style={{ width: 100, textAlign: "end" }}>
            <Text>{formatOdds(bs.odds)}</Text>
            {bet.each_way && (
              <Text type="secondary"> (EW: 1/{bs.selection.ew_divisor})</Text>
            )}
          </div>
        </div>
      ))}
      <br />
      <Text strong>Total Stake: </Text>
      <Text>
        <Money amount={bet.stake} currency={bet.stake_currency} />
      </Text>
    </>
  );
};

export default BetDetails;
