import { Typography, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import InstallAppButtons from "../components/InstallAppButtons";

const { Title, Text } = Typography;

const RunnerLanding = ({ user, logout }) => {
  return (
    <>
      <Space style={styles.logout}>
        <UserOutlined style={styles.text} />
        <Text style={styles.text}>{user.username}</Text>
        <Text onClick={logout} style={{ ...styles.text, cursor: "pointer" }}>
          Logout
        </Text>
      </Space>
      <div style={styles.container}>
        <div>
          <Title style={styles.text} level={2}>
            Install Runner
          </Title>
        </div>
        <InstallAppButtons />
      </div>
    </>
  );
};

export default RunnerLanding;

const styles = {
  logout: {
    position: "absolute",
    top: "2em",
    right: "2em",
    zIndex: 2,
  },
  container: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "radial-gradient(var(--primary), #000000)",
  },
  text: {
    color: "white",
  },
};
