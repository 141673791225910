import { useState } from "react";
import { Popover, Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import StatusTag from "./StatusTag";
import statusColors from "./statusColors";
import SettlementTypeIndicator from "./SettlementTypeIndicator";

const ResultEditable = ({ selection, settle, onResultChange, title }) => {
  const [status, setStatus] = useState(selection.result);
  const [pickerVisible, setPickerVisible] = useState(false);
  const breakpoint = useBreakpoint();

  const [loading, setLoading] = useState(null);

  const handleSelected = async (result) => {
    setLoading(result);
    const res = await settle(result);
    setPickerVisible(false);
    if (res.ok) {
      setLoading(null);
      setStatus(result);
      onResultChange(res, result);
    }
  };

  return (
    <Popover
      content={
        <Space
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {Object.keys(statusColors)
            .filter((s) => s !== selection.result)
            .map((status) => (
              <StatusTag
                loading={loading === status ? 1 : 0}
                result={status}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelected(status);
                }}
                key={status}
              >
                {status}
              </StatusTag>
            ))}
        </Space>
      }
      trigger="click"
      onClick={(e) => e.stopPropagation()}
      placement={breakpoint.xs ? "bottom" : "right"}
      visible={pickerVisible}
      onVisibleChange={setPickerVisible}
    >
      <StatusTag result={status}>{title}</StatusTag>
    </Popover>
  );
};

export default ResultEditable;
