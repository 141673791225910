import React from "react";
import { Tag, Typography, Space, Row, Col, Anchor } from "antd";
import { SettingOutlined, SwapOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routes } from "../../App";
import StatusTag from "../components/result/StatusTag";
import formatFlagType from "../tools/formatFlagType";

const { Text, Title, Paragraph } = Typography;

const PageRef = ({ route }) => (
  <Link to={route.path} target="_blank">
    {/* {route.icon} */}
    {route.title}
  </Link>
);

const Quote = (props) => <Text code>{props.children}</Text>;

const FlagDescription = ({ flag }) => (
  <Space direction="vertical" size="small" key={flag.title}>
    <Quote>{formatFlagType(flag.title)}</Quote>
    <Text>{flag.description}</Text>
  </Space>
);

const exampleSection = {
  title: "Section",
  key: "key",
  subsections: [
    {
      title: "SubsectionTitle",
      key: "subsection_key",
      content: <></>,
    },
  ],
};

const resultTypes = [
  { title: "ACTIVE", description: <>The initial status of all selections</> },
  {
    title: "WINNER",
    description: null,
  },
  { title: "LOSER", description: null },
  { title: "PLACED", description: <>Applies to each way markets only</> },
  { title: "REMOVED", description: <>Non runner, event abandoned etc.</> },
  { title: "PUSH", description: <>Applied to handicap markets only</> },
];

const flagTypes = [
  {
    title: "ODDS_BELOW_REQUESTED",
    description: (
      <>
        The runner confirmed a bet at odds lower than what was requested for the
        selection.
      </>
    ),
  },
  {
    title: "CONFIRMED_AFTER_SELECTION_START_TIME",
    description: (
      <>
        The runner confirmed the bet after the scheduled event start time. This
        may indicate the runner waited to see the result before confirming the
        bet. If a losing bet is confirmed after the event result is known, the
        runner may have been attempting to pocket the winnings, or may have
        pocketed the stake and simply wouldn't have confirmed the bet if it had
        won.
      </>
    ),
  },
  {
    title: "PAYOUT_BELOW_EXPECTED",
    description: (
      <>The runner confirmed a bet payout below the expected amount.</>
    ),
  },
  {
    title: "PAYOUT_NOT_CONFIRMED",
    description: (
      <>
        The runner failed to confirm a payout within one hour of ending their
        betting session. Bets with payouts in need of confirmation are clearly
        highlighted within the placed bets section of the app.
      </>
    ),
  },
  {
    title: "BETSLIP_NOT_PROVIDED",
    description: (
      <>
        The runner failed to upload betslip images within one hour of ending
        their betting session.
      </>
    ),
  },
  {
    title: "FLOAT_NOT_CONFIRMED",
    description: (
      <>
        The runner failed to confirm their float within one hour of ending their
        betting session.
      </>
    ),
  },
  {
    title: "FLOAT_BELOW_EXPECTED",
    description: <>The runner confirmed their float below what was expected.</>,
  },
  {
    title: "TOOK_SCREENSHOT",
    description: (
      <>
        The runner took a screenshot within the iOS app. The app blocks screen
        recording on iOS and Android and blocks screenshots on Android. Blocking
        screenshots is not currently possible for iOS users, so in the case an
        iOS user does take a screenshot, we generate a flag.
      </>
    ),
  },
  // { title: "OTHER", description: <></> },
];

const Help = () => {
  const sections = [
    {
      title: "Selections",
      key: routes.SELECTIONS.key,
      subsections: [
        {
          title: "Adding a selection",
          key: "selections_adding",
          content: (
            <>
              <Paragraph>
                To add a selection, go to the{" "}
                <PageRef route={routes.NEW_SELECTION} /> tab. Use the racecard
                to autofill the event and runner details or enter the details
                manually. Your selection will appear in the betslip. Enter your
                minimum odds, update the expiry time if required and click{" "}
                <Quote>Add Selection</Quote>. Runners will be notified and can
                start placing bets with the selection.
              </Paragraph>
              <Paragraph>
                There are a few additional options available for limiting the
                type of bets runners can create from the selection. The{" "}
                <Quote>Singles only</Quote> and
                <Quote>Multiples Only</Quote> switches can be used to ensure
                that the bet is only placed as a single or a multiple. For
                markets where each way bets are possible, you can choose to
                disallow each way or enforce each way only with the{" "}
                <Quote>Allow each way</Quote> and <Quote>Each way only</Quote>{" "}
                switches.
              </Paragraph>
              <Paragraph>
                The default values used by the new selection form can be
                configured within the <PageRef route={routes.SETTINGS} /> tab.
                Save yourself clicks by setting your default event type, default
                bet input tab (racecard or manual), and default selection
                expiry. These settings are unique to each user, and should
                definitely be explored by anyone using the system to input large
                numbers of selections.
              </Paragraph>
            </>
          ),
        },
        {
          title: "View Selections",
          key: "view_selections",
          content: (
            <>
              <Paragraph>
                A list of all selections can be found within the{" "}
                <PageRef route={routes.SELECTIONS} /> tab. By default you will
                see the selections for the current day. Use the date picker to
                view a different date or date range. Clicking a selection takes
                you to its detail page.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Selection Expiry",
          key: "selection_expiry",
          content: (
            <>
              <Paragraph>
                Each selection has an expiry time after which it will no longer
                be visible to runners and cannot be used to create any more
                bets. This is set when adding the selection. An active selection
                can be expired at any time from the{" "}
                <PageRef route={routes.SELECTIONS} /> tab. Simply click the
                green button (e.g.{" "}
                <Tag color="green" style={{ margin: 0 }}>
                  3 mins
                </Tag>{" "}
                ) that says how much time the selection has before it expires.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Selection Results",
          key: "selection_results",
          content: (
            <>
              <Paragraph>
                <Space direction="vertical">
                  {resultTypes.map((r, i) => (
                    <Space key={i}>
                      <StatusTag result={r.title}>{r.title}</StatusTag>
                      <Text type="secondary">{r.description}</Text>
                    </Space>
                  ))}
                </Space>
              </Paragraph>
              <Paragraph>
                Selections added via the racecard will be settled automatically
                once the results become available. A selection result can set or
                updated manually by clicking on its status within the{" "}
                <PageRef route={routes.SELECTIONS} /> tab and choosing from the
                available statuses.
              </Paragraph>
              <Paragraph>
                When a selection is settled, all runner floats are updated
                immediately according to the expected payout of their bets given
                the result. If the result is then changed, or the runner
                confirms a payout different to the expected payout, a float
                adjustment is applied.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Settlement Tab",
          key: "settlement_tab",
          content: (
            <>
              <Paragraph>
                In cases were manual settlement is required, the{" "}
                <PageRef route={routes.SETTLEMENT} /> tab provides a convenient
                way for an operator to work through the queue of unsettled
                selections. Selections in need of manual settlement appear one
                at a time and can either be settled immediately or marked for
                settlement at a later time. Once one selection is dealt with,
                the next will appear until all selections have been handled.
              </Paragraph>
            </>
          ),
        },
      ],
    },
    // {
    //   title: "Bets",
    //   key: "bets",
    //   subsections: [
    //     {
    //       title: null,
    //       key: "about_bets",
    //       content: (
    //         <>
    //           <Paragraph>
    //             Runners can create bets from active selections. A bet can be
    //             created using just one selection (i.e. a single) or using a
    //             number of selections (doubles, trebles, lucky 15s etc.). The
    //             types of bet a selection can be used for can be limited when the
    //             selection is created (each way only, singles only, multiples
    //             only etc.). Runners compare each selection's requested odds to
    //             odds available with the bookmaker and build bets using
    //             selections for which the right odds are available. They then
    //             place the bet with the bookmaker and confirm it with the app.
    //           </Paragraph>
    //           <Paragraph>
    //             In the simplest case, a selection is added and the runner is
    //             notified. The runner places the bet and confirms it in the app.
    //           </Paragraph>
    //         </>
    //       ),
    //     },
    //   ],
    // },
    {
      title: "Runners",
      key: routes.RUNNERS.key,
      subsections: [
        {
          title: "Adding a runner",
          key: "add_runner",
          content: (
            <>
              <Paragraph>
                To add a runner, go to the <PageRef route={routes.RUNNERS} />{" "}
                tab and click <Quote>Add Runner</Quote>. The values for{" "}
                <Quote>Betting level</Quote> and <Quote>Odds format</Quote> can
                be updated later by the runners themselves.
              </Paragraph>
              <Paragraph>
                Betting level is the value the runner's default stake will
                target, plus or minus some random variation. For example, if
                betting level is set to £1000 the default stake for a 9/1 single
                will be around £100.
              </Paragraph>
              <Paragraph>
                Once a runner is added, their login details will be displayed
                and can be copied and sent to them. If an email address was
                provided, these details will be sent to the runner
                automatically. On first login, the runner will be prompted to
                set their own secure password. These details can be used to log
                into the app or this site. A runner that logs into this site
                will only be able to view links to the Android and iOS apps.
                {/* TODO: Add password reset flow to app */}
              </Paragraph>
            </>
          ),
        },
        {
          title: "View Runners",
          key: "view_runners",
          content: (
            <>
              <Paragraph>
                A list of all runners can be found within the{" "}
                <PageRef route={routes.RUNNERS} /> tab. Clicking a runner in
                this list takes you to their detail page.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Runner Floats",
          key: "runner_floats",
          content: (
            <>
              <Paragraph>
                A runner's cash balance is tracked by their float. Each time
                they place a bet, the stake is automatically deducted from their
                float. When a selection is settled, expected payouts are
                calculated and are added to the runners's float balance. If a
                selection result changes, or the runner confirms a payout
                different to the expected payout, a float adjustment is applied.
                All changes to runner floats are recorded as float transfers,
                which can be seen on the runner's detail page.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Deposit/Withdraw",
          key: "deposit_withdraw",
          content: (
            <>
              <Paragraph>
                When a runner is given funds, or hands in their returns, this
                should be recorded by depositing or withdrawing the relevant
                amount from the runner's float. Go to the runners detail page
                and click the <SwapOutlined /> button. Enter the amount and
                select <Quote>Deposit</Quote>,<Quote>Withdraw</Quote> or{" "}
                <Quote>To Runner</Quote> from the dropdown box.
              </Paragraph>
              <Paragraph>
                The <Quote>To Runner</Quote> option enables easy transfers of
                funds between runners.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Deactivating a runner",
          key: "deactivate_runner",
          content: (
            <>
              <Paragraph>
                Go to the <PageRef route={routes.RUNNERS} /> tab and click on
                the runner you would like to deactivate to go to their detail
                page. Click the <SettingOutlined /> icon to the right of their
                name and click
                <Quote>Deactivate Runner</Quote>. The runner will be locked out
                of the system immediately.
              </Paragraph>
              <Paragraph>
                To view deactivated runners, click{" "}
                <Quote>Show Deactivated</Quote> at the bottom of the runner list
                within <PageRef route={routes.RUNNERS} /> tab. A deactivated
                runner can be reactivated through their runner detail page.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Resetting a runner's password",
          key: "runner_password_reset",
          content: (
            <>
              <Paragraph>
                If a runner loses access to their login details, a staff user
                can reset their password for them. Go to the{" "}
                <PageRef route={routes.RUNNERS} /> tab and click on the runner
                to go to their detail page. Click the <SettingOutlined /> icon
                to the right of their name and click{" "}
                <Quote>Reset Password</Quote>. A new temporary password will be
                automatically generated which can be copied and sent to the
                runner.
              </Paragraph>
            </>
          ),
        },
      ],
    },
    {
      title: "Staff",
      key: routes.STAFF.key,
      subsections: [
        {
          title: null,
          key: "staff_intro",
          content: (
            <>
              <Paragraph>Staff users have access to this admin site.</Paragraph>
            </>
          ),
        },
        {
          title: "Adding staff",
          key: "adding_staff",
          content: (
            <>
              <Paragraph>
                To add a staff user, go to the <PageRef route={routes.STAFF} />{" "}
                tab and click <Quote>Add Staff User</Quote>.
              </Paragraph>
              <Paragraph>
                You can optionally make the new staff user a superuser, meaning
                they will be able to create and manage other staff accounts.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Deactivating staff",
          key: "deactivating_staff",
          content: (
            <>
              <Paragraph>
                <Text strong>Only superusers can deactivate a staff user.</Text>
                <br />
                Go to the <PageRef route={routes.STAFF} /> tab and click on the
                staff user you would like to deactivate. Click
                <Quote>Deactivate User</Quote>. The user will be locked out of
                the system immediately.
              </Paragraph>
              <Paragraph>
                To view deactivated users, click <Quote>Show Deactivated</Quote>{" "}
                at the bottom of the user list within{" "}
                <PageRef route={routes.STAFF} /> tab. A deactivated user can be
                reactivated by clicking their name and clicking{" "}
                <Quote>Reactivate User</Quote>.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Resetting a staff user password",
          key: "reset_staff_password",
          content: (
            <>
              <Paragraph>
                <Text strong>
                  Only superusers can reset a staff user password.
                </Text>
                <br /> If a staff user loses access to their login details, a
                superuser can reset their password for them. Go to the{" "}
                <PageRef route={routes.STAFF} /> tab, click on the user and
                click <Quote>Reset Password</Quote>. A new temporary password
                will be automatically generated which can be copied and sent to
                the staff user. On login, the staff user will be prompted to set
                their own secure password.
              </Paragraph>
            </>
          ),
        },
      ],
    },
    {
      title: "Flags",
      key: routes.FLAGS.key,
      subsections: [
        {
          title: null,
          key: "about_flags",
          content: (
            <>
              <Paragraph>
                Flags keep track of unwanted behaviour by runners using the
                system. The following flags are generated automatically by the
                system:
              </Paragraph>
              <Paragraph>
                <Space direction="vertical" size="middle">
                  {flagTypes.map((f, i) => (
                    <FlagDescription flag={f} key={i} />
                  ))}
                </Space>
              </Paragraph>
            </>
          ),
        },
        {
          title: "Managing Flags",
          key: "managing_flags",
          content: (
            <Paragraph>
              Flags can be viewed and managed from the{" "}
              <PageRef route={routes.FLAGS} /> tab. An operator can easily work
              through the queue of new flags by clicking{" "}
              <Quote>View all unhandled</Quote> and selecting stack mode.
              Unhandled flags will appear and can be assessed one at a time.
              Notes and a severity rating can be added, and the flag can be
              marked as handled or set to handle later. This is allows the
              operator to contact the runner if necessary, handling the flag
              later once the runner has had a chance to respond.
            </Paragraph>
          ),
        },
      ],
    },
    // {
    //   title: "Reports",
    //   key: routes.REPORTS.key,
    //   subsections: [
    //     {
    //       title: "View Reports",
    //       key: "view_reports",
    //       content: <>lol</>,
    //     },
    //     {
    //       title: "Data Browser",
    //       key: "data_browser",
    //       content: <>lol</>,
    //     },
    //   ],
    // },
    // {
    //   title: "Billing",
    //   key: routes.BILLING.key,
    //   subsections: [
    //     {
    //       title: null,
    //       key: "about_billing",
    //       content: <>Billing</>,
    //     },
    //   ],
    // },
    // {
    //   title: "Api",
    //   key: routes.API.key,
    //   subsections: [
    //     {
    //       title: null,
    //       key: "about_api",
    //       content: <>Lol</>,
    //     },
    //   ],
    // },
    // {
    //   title: "Runner Agent App",
    //   key: routes.APP.key,
    //   subsections: [
    //     {
    //       title: null,
    //       key: "about_app",
    //       content: <>lol</>,
    //     },
    //   ],
    // },
    // {
    //   title: "Section",
    //   key: "key",
    //   subsections: [
    //     {
    //       title: "SubsectionTitle",
    //       key: "subsection_key",
    //       content: <></>,
    //     },
    //   ],
    // },
  ];
  return (
    <Row gutter={[16, 16]}>
      <Col span={24} md={18}>
        {sections.map((s, i) => (
          <div key={s.key} style={{ marginTop: i !== 0 && "3em" }}>
            <Title level={2} id={s.key}>
              {s.title}
            </Title>
            {s.subsections.map((sub) => (
              <div key={sub.key} style={{ marginTop: "2em" }}>
                {sub.title && (
                  <Title level={5} id={sub.key}>
                    {sub.title}
                  </Title>
                )}
                {sub.content}
              </div>
            ))}
          </div>
        ))}
      </Col>
      <Col span={0} md={6}>
        <Anchor offsetTop={90}>
          {sections.map((s) => (
            <Anchor.Link href={`#${s.key}`} title={s.title} key={s.key}>
              {s.subsections.map((sub) => (
                <Anchor.Link
                  href={`#${sub.key}`}
                  title={sub.title}
                  key={sub.key}
                />
              ))}
            </Anchor.Link>
          ))}
        </Anchor>
      </Col>
    </Row>
  );
};

export default Help;
