import "antd/dist/antd.css";
import "antdash/styles/antdash.css";
import "./App.css";
import {
  TeamOutlined,
  BugOutlined,
  DatabaseOutlined,
  UserOutlined,
  CheckOutlined,
  SettingOutlined,
  LineChartOutlined,
  CodeOutlined,
  MobileOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import { BsReceipt } from "react-icons/bs";
import { BiSelectMultiple } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import Icon from "@ant-design/icons";
import { useAuthStorage, PasswordResetRequired } from "antdash";
import logo from "./logo-white.svg";
import validateUser from "./app/tools/validateUser";
import NewSelection from "./app/routes/NewSelection";
import Selections from "./app/routes/Selections";
import SelectionDetail from "./app/routes/SelectionDetail";
import NextSettlement from "./app/routes/NextSettlement";
import Runners from "./app/routes/Runners";
import Staff from "./app/routes/Staff";
import RunnerDetail from "./app/routes/RunnerDetail";
import Settings from "./app/routes/Settings";
import RunnerLanding from "./app/routes/RunnerLanding";
import Api from "./app/routes/Api";
// import Api from "./app/routes/Api_NEW";
import InstallApp from "./app/routes/InstallApp";
import Report from "./app/routes/Report";
import Billing from "./app/routes/Billing";
import { useEffect, useState } from "react";
import extraApi from "./app/api/extra";
import AppContext from "./app/context/AppContext";
import defaultConfig from "./app/tools/defaultConfig";
import Flags from "./app/routes/Flags";
import FlagDetail from "./app/routes/FlagDetail";
import UnhandledFlags from "./app/routes/UnhandledFlags";
import VerifyBetslips from "./app/routes/VerifyBetslips";
import { message } from "antd";
import Login from "./app/routes/Login";
import Help from "./app/routes/Help";
import AppNavigator from "./app/components/AppNavigator";

export const routes = {
  // NEW_SELECTION: {
  //   title: "New Selection",
  //   key: "new_selection",
  //   icon: <Icon component={AiOutlinePlus} />,
  //   path: "/",
  //   exact: true,
  //   component: NewSelection,
  //   menu: true,
  //   superuserOnly: false,
  // },
  SELECTIONS: {
    title: "Selections",
    key: "selections",
    icon: <Icon component={BsReceipt} />,
    path: "/",
    exact: true,
    component: Selections,
    menu: true,
    superuserOnly: false,
  },
  SELECTION_DETAIL: {
    title: "Selection Detail",
    key: "selection_detail",
    icon: null,
    path: "/selections/:id",
    exact: false,
    component: SelectionDetail,
    menu: false,
    superuserOnly: false,
  },
  SETTLEMENT: {
    title: "Settlement",
    key: "settlement",
    icon: <CheckOutlined />,
    path: "/settlement",
    exact: false,
    component: NextSettlement,
    menu: true,
    superuserOnly: false,
  },
  RUNNERS: {
    title: "Runners",
    key: "runners",
    icon: <UserOutlined />,
    path: "/runners",
    exact: true,
    component: Runners,
    menu: true,
    superuserOnly: false,
  },
  RUNNER_DETAIL: {
    title: "Runner Detail",
    key: "runner_detail",
    icon: null,
    path: "/runners/:id",
    exact: false,
    component: RunnerDetail,
    menu: false,
    superuserOnly: false,
  },
  STAFF: {
    title: "Staff",
    key: "staff",
    icon: <TeamOutlined />,
    path: "/staff",
    exact: false,
    component: Staff,
    menu: true,
    superuserOnly: true,
  },
  REPORTS: {
    title: "Report",
    key: "report",
    icon: <LineChartOutlined />,
    path: "/report",
    exact: false,
    component: Report,
    menu: true,
    superuserOnly: false,
  },
  FLAGS: {
    title: "Flags",
    key: "flags",
    icon: <FlagOutlined />,
    path: "/flags",
    exact: true,
    component: Flags,
    menu: true,
    superuserOnly: false,
  },
  UNHANDLED_FLAGS: {
    title: "Unhandled Flags",
    key: "unhandled_flags",
    icon: null,
    path: "/flags/unhandled",
    exact: true,
    component: UnhandledFlags,
    menu: false,
    superuserOnly: false,
  },
  FLAG_DETAIL: {
    title: "Flag Detail",
    key: "flag_detail",
    icon: <FlagOutlined />,
    path: "/flags/detail/:id",
    exact: true,
    component: FlagDetail,
    menu: false,
    superuserOnly: false,
  },
  VERIFY_BETSLIPS: {
    title: "Verify Betslips",
    key: "verify_betslips",
    icon: <Icon component={BiSelectMultiple} />,
    path: "/verify_betslips",
    exact: false,
    component: VerifyBetslips,
    menu: true,
    superuserOnly: false,
  },
  SETTINGS: {
    title: "Settings",
    key: "settings",
    icon: <SettingOutlined />,
    path: "/settings",
    exact: false,
    component: Settings,
    menu: true,
    superuserOnly: false,
  },
  // DATA_BROWSER: {
  //   title: "Data Browser",
  //   key: "data_browser",
  //   icon: <DatabaseOutlined />,
  //   path: `${process.env.REACT_APP_BACKEND_URL}data-browser`,
  //   exact: false,
  //   external: true,
  //   component: null,
  //   menu: true,
  //   superuserOnly: true,
  // },
  API: {
    title: "Api",
    key: "api",
    icon: <CodeOutlined />,
    path: "/docs",
    exact: false,
    component: Api,
    menu: true,
    superuserOnly: false,
  },
  APP: {
    title: "App",
    key: "app",
    icon: <MobileOutlined />,
    path: "/app",
    exact: false,
    component: InstallApp,
    menu: true,
    superuserOnly: false,
  },
  // BILLING: {
  //   title: "Billing",
  //   key: "billing",
  //   icon: <CreditCardOutlined />,
  //   path: "/billing",
  //   exact: false,
  //   component: Billing,
  //   menu: true,
  //   superuserOnly: false,
  // },
  // HELP: {
  //   title: "Help",
  //   key: "help",
  //   icon: <QuestionCircleOutlined />,
  //   path: "/help",
  //   exact: false,
  //   component: Help,
  //   menu: true,
  //   superuserOnly: false,
  // },
  POLICY: {
    title: "Policy",
    key: "policy",
    icon: null,
    path: "/policy",
    exact: false,
    component: () => <h1>Policy</h1>,
    menu: false,
    superuserOnly: false,
  },
  // DEBUG: {
  //   title: "Debug",
  //   key: "debug",
  //   icon: <Icon component={BugOutlined} />,
  //   path: "/debug",
  //   exact: false,
  //   component: () => <div>Debug</div>,
  //   menu: true,
  //   superuserOnly: false,
  // },
};

function App() {
  const { user, setUser, removeUser, updateUserSettingsLocal } =
    useAuthStorage();

  const [config, setConfig] = useState(defaultConfig);
  useEffect(() => {
    const getConfig = async () => {
      const res = await extraApi.config();
      if (res.ok) {
        setConfig(res.data);
      } else {
        message.error("Error getting site config.");
      }
    };
    getConfig();
  }, []);

  if (!validateUser(user)) {
    return (
      <Login logo={logo} logoAlt={"Runner"} setUser={setUser} config={config} />
    );
  }

  if (user.settings.require_password_reset) {
    return (
      <PasswordResetRequired
        logo={logo}
        logoAlt={"Runner"}
        onSuccess={() =>
          updateUserSettingsLocal("require_password_reset", false)
        }
      />
    );
  }

  if (!user.is_staff) {
    return <RunnerLanding user={user} logout={removeUser} />;
  }

  return (
    <AppContext.Provider value={{ config }}>
      <AppNavigator
        logo={logo}
        routes={routes}
        user={user}
        logout={removeUser}
      />
    </AppContext.Provider>
  );
}

export default App;
