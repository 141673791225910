import { Space, Tag } from "antd";
import React from "react";

const EWOnlyIcon = () => (
  <div
    style={{
      width: 20,
      height: 14,
      fontSize: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid gray",
      borderRadius: 2,
      color: "white",
      backgroundColor: "gray",
      margin: 0,
    }}
  >
    EW
  </div>
);

export const AllowEachWay = () => <Tag>Each Way Enabled</Tag>;
export const EWOnly = () => <Tag>Each Way Only</Tag>;
export const MultiplesOnly = () => <Tag>Multiples Only</Tag>;
export const SinglesOnly = () => <Tag>Singles Only</Tag>;
