import { useContext, useState } from "react";
import {
  Row,
  Col,
  Tabs,
  message,
  Button,
  Form,
  Select,
  Input,
  Typography,
  Switch,
  Space,
  Tooltip,
} from "antd";
import { Racecard, ManualSelection, InputOdds } from "betui";
import { useAuthStorage, DatePicker, useApiList } from "antdash";
import { useForm, zodResolver } from "@mantine/form";
import { BsReceipt } from "react-icons/bs";
import { DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { z } from "zod";
import SyntaxHighlighter from "react-syntax-highlighter";
import AppContext from "../context/AppContext";
import selectionsApi from "../api/selections";
import trackingGroupsApi from "../api/trackingGroups";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { addMinutes, isEqual, differenceInMinutes, max, min } from "date-fns";
import { upperFirst } from "lodash";
import formatNumberSign from "../tools/formatNumberSign";
import formatOffset from "../tools/formatOffset";
import { Link } from "react-router-dom";
import { routes } from "../../App";

const { TabPane } = Tabs;
const { Text } = Typography;

const defaultHandicap = (handicap_details) => {
  return handicap_details !== null
    ? parseInt(
        (handicap_details.minUnitValue + handicap_details.maxUnitValue) /
          2 /
          handicap_details.interval
      ) * handicap_details.interval
    : 0;
};

const schema = z.object({
  event: z.string(),
  event_type: z.string(),
  event_start_time: z.date(),
  market_id: z.string(),
  selection_id: z.string(),
  runner: z.string(),
  // odds: z.union([z.number().gt(1), z.string().regex(/^[0-9]+\/[0-9]+$/)]),
  odds: z.union([z.number().gt(1), z.string()]),
  ew_divisor: z.number(),
  handicap_details: z.union([z.object(), z.null()]),
  handicap_market: z.boolean(),
  handicap: z.number(),
  expires: z.date(),
  allow_each_way: z.boolean(),
  each_way_only: z.boolean(),
  singles_only: z.boolean(),
  multiples_only: z.boolean(),
  note: z.string(),
  tracking_groups: z.array(z.string()),
});

const NewSelection = () => {
  const { user } = useAuthStorage();
  const { config } = useContext(AppContext);
  const breakpoint = useBreakpoint();

  const [loading, setLoading] = useState(false);

  const now = new Date();
  const disabledDate = (current) => current < now;

  const trackingGroups = useApiList(trackingGroupsApi, "tracking_groups");

  const getDefaultExpiry = (event_start_time) => {
    const now = new Date();
    const eventStartTime = new Date(event_start_time);
    const base = user.settings.default_selection_expiry_base;
    const offset = user.settings.default_selection_expiry_offset;
    if (base === "NOW_PLUS") {
      return min([addMinutes(now, offset), eventStartTime]);
    } else {
      return max([addMinutes(eventStartTime, -offset), addMinutes(now, 1)]);
    }
  };

  const form = useForm({
    schema: zodResolver(schema),
    initialValues: {
      event: "",
      event_type: "",
      event_start_time: "",
      market_id: "",
      selection_id: "",
      runner: "",
      odds: "",
      ew_divisor: "",
      handicap_details: "",
      handicap_market: "",
      handicap: "",
      expires: "",
      allow_each_way: user.settings.default_allow_each_way,
      each_way_only: user.settings.default_each_way_only,
      singles_only: user.settings.default_singles_only,
      multiples_only:
        user.settings.default_multiples_only &&
        !user.settings.default_singles_only,
      note: "",
      tracking_groups: user.settings.default_tracking_groups.map((x) => x.name),
    },
  });

  const updateBetslip = (item, add) => {
    const current = form.values;
    if (add) {
      form.setValues({
        ...current,
        event: item.event,
        event_type: item.event_type,
        event_start_time: new Date(item.event_start_time),
        market_id: item.market_id,
        selection_id: item.selection_id,
        runner: item.runner,
        odds: "",
        ew_divisor: item.ew_divisor,
        handicap_details: item.handicap_details,
        handicap_market: item.handicap_details !== null,
        handicap: defaultHandicap(item.handicap_details),
        expires: getDefaultExpiry(item.event_start_time),
        allow_each_way:
          item.ew_divisor === null
            ? false
            : user.settings.default_allow_each_way,
        each_way_only:
          item.ew_divisor === null
            ? false
            : user.settings.default_each_way_only,
      });
    } else {
      form.setValues({
        ...current,
        event: "",
        event_type: "",
        event_start_time: "",
        market_id: "",
        selection_id: "",
        runner: "",
        ew_divisor: "",
        handicap_details: "",
        handicap_market: "",
        handicap: "",
        expires: "",
      });
    }
  };

  const submit = async () => {
    // form.validate();
    setLoading(true);
    const postData = {
      mode: "MANUAL",
      ...form.values,
      handicap_details: undefined,
    };
    const res = await selectionsApi.create(postData);
    if (res.ok) {
      form.reset();
      trackingGroups.refresh();
      message.success("Bet added.");
    } else {
      res.status === 400 && form.setErrors(res.data);
    }
    setLoading(false);
  };

  const sendRandom = async () => {
    setLoading(true);
    const res = await selectionsApi.sendRandomBet();
    if (res.ok) {
      message.success("Random bet sent.");
    } else {
      message.error("Error sending random bet.");
    }
    setLoading(false);
  };

  return (
    <Row gutter={32} style={{ height: "100%" }} align="middle">
      <Col span={24} lg={12} style={{ alignSelf: "flex-start" }}>
        <Tabs defaultActiveKey={user.settings.default_bet_input} centered>
          <TabPane tab="Racecard" key="Racecard">
            <Racecard updateBetslip={updateBetslip} />
          </TabPane>
          <TabPane tab="Manual" key="Manual">
            <ManualSelection updateBetslip={updateBetslip} />
          </TabPane>
        </Tabs>
      </Col>
      <Col span={24} lg={12} align="center">
        {form.values.runner ? (
          <>
            {/* <SyntaxHighlighter>{JSON.stringify(form.values)}</SyntaxHighlighter> */}
            <Form
              layout="vertical"
              requiredMark="optional"
              autoComplete="off"
              style={{ maxWidth: 500 }}
              onFinish={submit}
            >
              <Row gutter={[8, 8]} justify="space-between">
                <Col>
                  <Text strong>Adding Selection</Text>
                </Col>
                <Col>
                  <Space>
                    <Link to={routes.SETTINGS.path} style={{ color: "black" }}>
                      <Tooltip title="Betslip settings">
                        {routes.SETTINGS.icon}
                      </Tooltip>
                    </Link>
                    <Tooltip title="Clear betslip">
                      <CloseOutlined
                        onClick={() => updateBetslip(null, false)}
                      />
                    </Tooltip>
                  </Space>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]} align="top">
                    <Col span={24} xl={13}>
                      <div
                        style={{
                          ...styles.selectionCol,
                          paddingTop: breakpoint.xl ? 40 : 10,
                          paddingBottom: 8,
                        }}
                      >
                        <Text type="secondary">
                          {form.values.event}: {form.values.runner}
                        </Text>
                      </div>
                    </Col>
                    <Col span={15} xl={7}>
                      <div style={styles.selectionCol}>
                        <Form.Item label={breakpoint.xl && "Odds"} required>
                          <InputOdds
                            required
                            key={form.values.odds}
                            style={{ marginBottom: 8, width: "100%" }}
                            autoFocus
                            {...form.getInputProps("odds")}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={9} xl={4}>
                      <div style={styles.selectionCol}>
                        <Form.Item
                          required
                          label={breakpoint.xl && "HC"}
                          tooltip={
                            form.values.handicap_details
                              ? `${form.values.handicap_details.minUnitValue} to ${form.values.handicap_details.maxUnitValue}`
                              : null
                          }
                          style={{ marginBottom: 8 }}
                        >
                          {form.values.handicap_details ? (
                            <Input
                              required
                              type="number"
                              min={form.values.handicap_details.minUnitValue}
                              max={form.values.handicap_details.maxUnitValue}
                              step={form.values.handicap_details.interval}
                              {...form.getInputProps("handicap")}
                            />
                          ) : (
                            <Input disabled />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <Row gutter={8} justify="space-between">
                    <Col>
                      <Text
                        type={form.values.ew_divisor === null && "secondary"}
                      >
                        Allow Each Way
                      </Text>
                    </Col>
                    <Col>
                      <Switch
                        size="small"
                        disabled={form.values.ew_divisor === null}
                        checked={form.values.allow_each_way}
                        onChange={(value) => {
                          !value && form.setFieldValue("each_way_only", false);
                          form.setFieldValue("allow_each_way", value);
                        }}
                      />
                    </Col>
                  </Row>
                  {form.values.allow_each_way && (
                    <Row gutter={8} justify="space-between">
                      <Col>
                        <Text>Each Way Only</Text>
                      </Col>
                      <Col>
                        <Switch
                          size="small"
                          checked={form.values.each_way_only}
                          onChange={(value) =>
                            form.setFieldValue("each_way_only", value)
                          }
                        />
                      </Col>
                    </Row>
                  )}
                  <Row gutter={8} justify="space-between">
                    <Col>
                      <Text>Singles Only</Text>
                    </Col>
                    <Col>
                      <Switch
                        size="small"
                        checked={form.values.singles_only}
                        onChange={(value) => {
                          form.setFieldValue("singles_only", value);
                          if (value && form.values.multiples_only) {
                            form.setFieldValue("multiples_only", false);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8} justify="space-between">
                    <Col>
                      <Text>Multiples Only</Text>
                    </Col>
                    <Col>
                      <Switch
                        size="small"
                        checked={form.values.multiples_only}
                        onChange={(value) => {
                          form.setFieldValue("multiples_only", value);
                          if (value && form.values.singles_only) {
                            form.setFieldValue("singles_only", false);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24} style={{ marginBottom: 24 }}>
                  <Space
                    direction="vertical"
                    style={{ width: "100%", textAlign: "start" }}
                  >
                    <Text>
                      Expires
                      <br />
                      Now: {formatOffset(form.values.expires, new Date())},
                      Event:{" "}
                      {formatOffset(
                        form.values.expires,
                        form.values.event_start_time
                      )}
                    </Text>

                    <DatePicker
                      // key={form.values.expires.toString()}
                      value={form.values.expires}
                      onChange={(v) => form.setFieldValue("expires", v)}
                      allowClear={false}
                      disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      showSecond={false}
                    />
                    {form.values.expires > form.values.event_start_time && (
                      <Text type="danger">
                        {" "}
                        Expiry time past event start time
                      </Text>
                    )}
                    {form.values.expires < new Date() && (
                      <Text type="danger"> Expiry time already passed</Text>
                    )}
                  </Space>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Space style={{ marginTop: 8 }}>
                      <Button
                        size="small"
                        onClick={() =>
                          form.setFieldValue(
                            "expires",
                            form.values.event_start_time
                          )
                        }
                        type={
                          isEqual(
                            form.values.expires,
                            form.values.event_start_time
                          ) && "primary"
                        }
                      >
                        Event Time
                      </Button>
                      <Button
                        size="small"
                        onClick={() =>
                          form.setFieldValue(
                            "expires",
                            addMinutes(new Date(), 1)
                          )
                        }
                        type={
                          differenceInMinutes(
                            new Date(),
                            form.values.expires
                          ) === 0 && "primary"
                        }
                      >
                        Now
                      </Button>
                    </Space>
                    <Space style={{ marginTop: 8 }}>
                      {[-10, -5, 5, 10].map((inc) => (
                        <Button
                          disabled={
                            addMinutes(form.values.expires, inc) >
                              form.values.event_start_time ||
                            addMinutes(form.values.expires, inc) < new Date()
                          }
                          key={inc}
                          size="small"
                          onClick={() =>
                            form.setFieldValue(
                              "expires",
                              addMinutes(form.values.expires, inc)
                            )
                          }
                        >
                          {formatNumberSign(inc)}
                        </Button>
                      ))}
                    </Space>
                  </div>
                </Col>

                <Col span={24}>
                  <Form.Item label="Tracking Groups">
                    <Select
                      mode="tags"
                      style={{ width: "100%", textAlign: "start" }}
                      placeholder="Add Tracking Groups..."
                      value={form.values.tracking_groups}
                      onChange={(v) => form.setFieldValue("tracking_groups", v)}
                    >
                      {trackingGroups.list.map(({ name }) => (
                        <Select.Option value={name} key={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Note">
                    <Input placeholder="Note" {...form.getInputProps("note")} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {Object.entries(form.errors).map(([x, i], index) => (
                    <Text type="danger" key={index}>
                      {upperFirst(x)}: {i}
                    </Text>
                  ))}
                </Col>
                <Col align="middle" span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Add Selection
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <div style={styles.container}>
            <BsReceipt style={{ height: 80, width: 80 }} />
            <div>Betslip Empty</div>
            {config.DEMO && (
              <Button onClick={sendRandom} loading={loading} type="link">
                Random
              </Button>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#c0c0c0",
    alignItems: "center",
    padding: 16,
    height: "100%",
  },
  selectionCol: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
};

export default NewSelection;
