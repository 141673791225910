import { Tooltip } from "antd";
import { Money } from "antdash";
import React from "react";

const AggregatedCurrencyMoney = ({
  detail,
  displayCurrency,
  useColor = false,
}) => {
  return (
    <Tooltip
      title={
        <>
          {Object.entries(detail.currency_total).map((x, i) => (
            <div key={i}>
              <Money amount={x[1]} currency={x[0]} style={{ color: "white" }} />
              <br />
            </div>
          ))}
        </>
      }
    >
      <span>
        <Money
          amount={detail.total[displayCurrency]}
          currency={displayCurrency}
          useColor={useColor}
        />
      </span>
    </Tooltip>
  );
};

export default AggregatedCurrencyMoney;
