import { useApi } from "antdash";
import { Row, Col, Button, Space, Image } from "antd";
import { CheckOutlined, CloseOutlined, FlagOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import betsApi from "../api/bets";
import Queue from "../components/Queue";
import { BiSelectMultiple } from "react-icons/bi";
import Icon from "@ant-design/icons";
import BetDetails from "../components/BetDetails";

const useUpdate = ({
  apiFunc,
  updateId,
  updateFields,
  onSuccess,
  debug = false,
}) => {
  const [loading, setLoading] = useState(false);
  const update = async () => {
    setLoading(true);
    const res = await apiFunc.update(updateId, updateFields);
    console.log("VERIFIED", res);
    setLoading(false);
    debug && console.log(`DEBUG ${apiFunc} RES:`, res);
    if (res.ok) {
      onSuccess(res);
    }
  };
  return { loading, update };
};

const BetslipCompare = ({ next }) => {
  const bet = next.data[0];

  const reject = useUpdate({
    apiFunc: betsApi,
    updateId: bet.id,
    updateFields: { betslip: null, betslip_verified: false },
    onSuccess: next.refresh,
  });

  const verify = useUpdate({
    apiFunc: betsApi,
    updateId: bet.id,
    updateFields: { betslip_verified: true },
    onSuccess: next.refresh,
  });

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <Row align="middle" justify="center" gutter={[16, 16]}>
          <Col span={24} md={12} align="middle">
            <Image
              style={{ maxHeight: "70vh" }}
              src={bet.betslip}
              alt="Betslip"
              // width="100%"
              preview={{ mask: <></> }}
            />
          </Col>
          <Col span={24} md={12}>
            <Row justify="end" gutter={[16, 16]}>
              <Col span={24}>
                <BetDetails bet={bet} />
              </Col>
              {/* <Col>
            <Button
            type="danger"
            icon={<FlagOutlined />}
            onClick={() => console.log("Click")}
            >
            Flag
            </Button>
          </Col> */}
              <Col>
                <Space>
                  <Button
                    type="secondary"
                    icon={<CloseOutlined />}
                    onClick={reject.update}
                    loading={reject.loading}
                  >
                    Reject
                  </Button>
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={verify.update}
                    loading={verify.loading}
                  >
                    Verify
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const VerifyBetslips = () => {
  const next = useApi({
    apiFunc: betsApi.list,
    defaultParams: {
      "filter{-betslip.eq}": "",
      "filter{betslip_verified}": false,
      sort: ["id"],
      per_page: 1,
    },
    unpackName: "bets",
    defaultData: [],
    debug: true,
  });
  return (
    <Queue
      next={next}
      Item={BetslipCompare}
      emptyMessage={"No betslips to verify."}
      EmptyIcon={(props) => <Icon {...props} component={BiSelectMultiple} />}
    />
  );
};

export default VerifyBetslips;
