import { useState } from "react";
import { useHistory } from "react-router-dom";
import { format, parse } from "date-fns";

// const dateFormat = "dd-MM-yyyy";
const dateFormat = "yyyy-MM-dd";

const useQueryDateRange = (defaultValue) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const initialFrom = params.has("from")
    ? parse(params.get("from"), dateFormat, new Date())
    : defaultValue[0];

  const initialTo = params.has("to")
    ? parse(params.get("to"), dateFormat, new Date())
    : defaultValue[1];

  const [value, setValue] = useState([initialFrom, initialTo]);

  const updateValue = (update) => {
    setValue(update);
    const addParams = new URLSearchParams({
      ...Object.fromEntries(params.entries()),
      from: format(update[0], dateFormat),
      to: format(update[1], dateFormat),
    }).toString();
    history.replace(`${history.location.pathname}?${addParams}`);
  };

  return [value, updateValue];
};

export default useQueryDateRange;
