import { useState } from "react";
import { Result, Button, Typography, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAuthStorage } from "antdash";
import InstallAppButtons from "../components/InstallAppButtons";
import NewRunnerSelf from "../components/forms/NewRunnerSelf";

const { Title } = Typography;

const InstallApp = () => {
  const { user, setUser } = useAuthStorage();

  console.log(user);

  const [addingSelf, setAddingSelf] = useState(false);

  if (!user.runner) {
    if (addingSelf) {
      return (
        <div style={styles.container}>
          <NewRunnerSelf user={user} setUser={setUser} />
        </div>
      );
    } else {
      return (
        <div style={styles.container}>
          <Result
            icon={<UserOutlined />}
            title="Runner profile required."
            subTitle="In order to use the app, you must first add yourself as a Runner."
            extra={
              <Button onClick={() => setAddingSelf(true)} type="primary">
                Create Runner
              </Button>
            }
          />
        </div>
      );
    }
  }

  return (
    <div style={styles.container}>
      <Space direction="vertical" align="center">
        <Title level={2}>Install Runner</Title>
        <InstallAppButtons />
      </Space>
    </div>
  );
};

export default InstallApp;

const styles = {
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
