import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const AppBreadcrumb = ({ routes }) => {
  const lastIndex = routes.length - 1;
  return (
    <Breadcrumb>
      {routes.map((route, index) =>
        index !== lastIndex ? (
          <Breadcrumb.Item key={index}>
            <Link to={route.path}>{route.title}</Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index}>{route.title}</Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};

export default AppBreadcrumb;
