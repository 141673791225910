import React from "react";
import { Row, Col, Typography, Card, Skeleton, Divider, Tag } from "antd";
import AggregatedCurrencyMoney from "./AggregatedCurrencyMoney";
import { formatOdds } from "betui";
import { useAuthStorage } from "antdash";
import statusColors from "./result/statusColors";
import { format } from "date-fns";
import {
  AllowEachWay,
  EWOnly,
  MultiplesOnly,
  SinglesOnly,
} from "./selectionLimitationIcons";

const { Title, Text } = Typography;

const SelectionCard = ({ selection, loading }) => {
  const { user } = useAuthStorage();
  const displayCurrency = user.settings.display_currency;
  return (
    <Card style={{ paddingBottom: 16 }}>
      <Skeleton loading={loading} active>
        {selection && (
          <Row gutter={[16, 16]} align="middle">
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Title level={4} style={{ marginBottom: 0 }}>
                    {selection.event}
                  </Title>
                </Col>
                <Col>
                  <Title level={4} style={{ marginBottom: 0 }} type="secondary">
                    {selection.event_type}
                  </Title>
                </Col>
              </Row>
              <Divider />
            </Col>
            <Col span={24}>
              <Row justify="space-between">
                <Col>
                  <Title level={4}>
                    {selection.runner} @ {formatOdds(selection.odds)}
                  </Title>
                </Col>
                <Col>
                  <Tag color={statusColors[selection.result]}>
                    {selection.result}
                  </Tag>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Text>
                Limitations: {selection.allow_each_way && <AllowEachWay />}
                {selection.each_way_only && <EWOnly />}
                {selection.singles_only && <SinglesOnly />}
                {selection.multiples_only && <MultiplesOnly />}
                <br />
                <br />
              </Text>
              <Text>
                Start Time:{" "}
                <Text type="secondary">
                  {format(new Date(selection.event_start_time), "p, PP")}
                </Text>
              </Text>
              <br />
              <Text>
                Handicap Market:{" "}
                <Text type="secondary">
                  {selection.handicap_market.toString()}
                </Text>
              </Text>
              <br />
              <Text>
                Market EW Divisor:{" "}
                <Text type="secondary">{selection.ew_divisor}</Text>
              </Text>
              <br />
              <Text>
                Rule 4: <Text type="secondary">{selection.rule_4}</Text>
              </Text>
              {selection.tracking_groups && (
                <Text>
                  <br />
                  Tracking Groups:{" "}
                  {selection.tracking_groups.map((tg) => (
                    <Tag>{tg.name}</Tag>
                  ))}
                </Text>
              )}
              {selection.note && (
                <Text>
                  <br />
                  Note: <Text type="secondary">{selection.note}</Text>
                </Text>
              )}
              <Divider />
              <Text>
                Total Staked:{" "}
                <AggregatedCurrencyMoney
                  detail={selection.total_staked}
                  displayCurrency={displayCurrency}
                />
              </Text>
              <br />
              <Text>
                Total Expected Payout:{" "}
                <AggregatedCurrencyMoney
                  detail={selection.total_expected}
                  displayCurrency={displayCurrency}
                />
              </Text>
              <br />
              <Text>
                Total Confirmed Payout:{" "}
                <AggregatedCurrencyMoney
                  detail={selection.total_confirmed}
                  displayCurrency={displayCurrency}
                />
              </Text>
              <br />
            </Col>
          </Row>
        )}
      </Skeleton>
    </Card>
  );
};

export default SelectionCard;
