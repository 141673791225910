import { client } from "antdash";

const report = (data) => client.get("report", data);

const billing = () => client.get("billing");

const config = () => client.get("config");

const extraApi = {
  report,
  billing,
  config,
};

export default extraApi;
