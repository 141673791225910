import React, { useState, useContext } from "react";
import { Table, Col, Row, Typography, Space, Button } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { upperFirst } from "lodash";
import { startOfMonth, startOfWeek, addWeeks, addDays, format } from "date-fns";
import AggregatedCurrencyMoney from "../components/AggregatedCurrencyMoney";
import { useApi, DatePicker, Money, useAuthStorage } from "antdash";
import extraApi from "../api/extra";
import AppContext from "../context/AppContext";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const Report = () => {
  const today = new Date();

  const { user } = useAuthStorage();

  const { config } = useContext(AppContext);

  console.log("CONFIG", config);

  const currencyCols = Object.entries(config.CURRENCY_SYMBOLS).map((x) => ({
    title: x[1],
    dataIndex: x[0],
    render: (amount) => <Money amount={amount} currency={x[0]} />,
  }));

  const columns = [
    {
      title: "",
      dataIndex: "field",
      render: upperFirst,
      className: "table-highlight",
    },
    ...currencyCols,
    {
      title: "Totals",
      dataIndex: "total",
      render: (total) => (
        <AggregatedCurrencyMoney
          detail={total}
          displayCurrency={user.settings.display_currency}
        />
      ),
      className: "table-highlight",
    },
  ];

  const [dateRange, setDateRange] = useState([
    startOfWeek(today, { weekStartsOn: 1 }),
    today,
  ]);

  const report = useApi({
    apiFunc: extraApi.report,
    defaultParams: { start_date: dateRange[0], end_date: dateRange[1] },
    debug: true,
  });

  const incrementWeek = (inc) => {
    const start = addWeeks(dateRange[0], inc);
    const end = addDays(start, 6);
    onDatesSelected([start, end]);
  };

  const onDatesSelected = (dateRange) => {
    setDateRange(dateRange);
    report.setParams({ start_date: dateRange[0], end_date: dateRange[1] });
  };

  const summary = () => {
    if (!report.data) {
      return <></>;
    }
    const data = report.data.summary;
    return (
      <>
        <Table.Summary.Row className="table-highlight">
          <Table.Summary.Cell>
            <b>Total Return</b>
          </Table.Summary.Cell>
          {Object.entries(config.CURRENCY_SYMBOLS).map((x) => (
            <Table.Summary.Cell key={x[0]}>
              <Money
                amount={data[user.settings.display_currency]}
                currency={x[0]}
                useColor
              />
            </Table.Summary.Cell>
          ))}
          <Table.Summary.Cell>
            <AggregatedCurrencyMoney
              detail={data.total}
              displayCurrency={user.settings.display_currency}
              useColor
            />
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} align="center">
        <Space>
          <Button
            icon={<DoubleLeftOutlined />}
            onClick={() => incrementWeek(-1)}
          />
          <RangePicker
            allowClear={false}
            value={dateRange}
            onChange={onDatesSelected}
            ranges={{
              Today: [today, today],
              "This Week": [startOfWeek(today), today],
              "This Month": [startOfMonth(today), today],
            }}
            format={(date) => format(date, "E dd/MM/yy")}
          />
          <Button
            icon={<DoubleRightOutlined />}
            onClick={() => incrementWeek(1)}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Table
          bordered
          pagination={false}
          scroll={{ x: "max-content" }}
          dataSource={report.data?.main}
          columns={columns}
          loading={report.loading}
          rowKey={(record) => record.id}
          size={"small"}
          summary={summary}
          footer={() => (
            <Text type="secondary">
              * Payout and wages shown for settled stake.
            </Text>
          )}
        />
      </Col>
    </Row>
  );
};

export default Report;
