import { client } from "antdash";

const resetPassword = (user_id) =>
  client.post("reset_user_password", { user_id });

const setActive = (user_id, active) =>
  client.post("set_user_active", { user_id, active });

const installLink = (platform) => client.post("install_link ", { platform });

const usersApi = {
  resetPassword,
  setActive,
  installLink,
};

export default usersApi;
