import { Tag } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import statusColors from "./statusColors";

const StatusTag = (props) => (
  <Tag
    color={statusColors[props.result]}
    style={{ marginRight: 0, cursor: "pointer" }}
    icon={props.loading ? <SyncOutlined spin /> : null}
    {...props}
  />
);

export default StatusTag;
