import { useContext, useState } from "react";
import { Form, Col, Row, Button, Select, Typography } from "antd";
import { useSubmit, getCurrencySymbol, InputMoney } from "antdash";
import { ODDS_FORMATS, ODDS_FORMAT_OPTIONS } from "betui";
import AppContext from "../../context/AppContext";
import staffApi from "../../api/staff";

const { Title } = Typography;

const NewRunnerSelf = ({ user, setUser }) => {
  const [currencySymbol, setCurrencySymbol] = useState(
    getCurrencySymbol(user.settings.display_currency)
  );

  const { config } = useContext(AppContext);
  const currencyOptions = Object.keys(config.CURRENCY_SYMBOLS).map((k) => ({
    label: k,
    value: k,
  }));

  console.log("USER:", user);

  const submit = (formData) =>
    staffApi.addStaffRunner({ user_id: "self", ...formData });

  const success = (res) => {
    setUser({ ...user, runner: res.data.runner }, user.remember);
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onFinish}
      initialValues={{
        float: 0,
        float_currency: user.settings.display_currency,
        odds_format: ODDS_FORMATS.FRACTIONAL.value,
        target_payout: 1500,
      }}
      style={{ maxWidth: 500, margin: "auto" }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Title level={5} style={{ marginBottom: "2em" }}>
            Creating runner profile for user: {user.username}
          </Title>
        </Col>
        <Col span={14}>
          <Form.Item
            name="float"
            label="Initial Float"
            rules={[
              { required: true, message: "Please provide initial float." },
            ]}
          >
            <InputMoney symbol={currencySymbol} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="float_currency"
            label="Currency"
            rules={[{ required: true, message: "Please select a currency." }]}
          >
            <Select
              onChange={(v) => setCurrencySymbol(getCurrencySymbol(v))}
              options={currencyOptions}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="target_payout"
            label="Betting Level"
            rules={[
              { required: true, message: "Please enter a betting level." },
            ]}
          >
            <InputMoney symbol={currencySymbol} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="odds_format"
            label="Odds Format"
            rules={[{ required: true, message: "Please select a format." }]}
          >
            <Select options={ODDS_FORMAT_OPTIONS} />
          </Form.Item>
        </Col>
        <Col span={24} align="middle">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitting}>
              Create Runner
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewRunnerSelf;
