import { client } from "antdash";

const list = (params) => client.get("staff", params);
const create = (formData) => client.post("staff", formData);
const addStaffRunner = (data) => client.post("add_staff_runner", data);

const staffApi = {
  list,
  create,
  addStaffRunner,
};

export default staffApi;
