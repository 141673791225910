import { useState } from "react";
import {
  Card,
  Divider,
  Rate,
  Table,
  Typography,
  Row,
  Image,
  Col,
  Space,
  Button,
  Tooltip,
  Menu,
  Dropdown,
} from "antd";
import {
  FlagOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { BreakLines, prettyDate, useApi } from "antdash";
import { addMinutes } from "date-fns";
import { Link } from "react-router-dom";
import flagsApi from "../api/flags";
import AddText from "../components/AddText";
import BetDetails from "../components/BetDetails";
import NullCell from "../components/NullCell";
import FlagsTable from "./FlagsTable";

const { Text, Title } = Typography;

const columns = [
  {
    title: "Date",
    dataIndex: "time",
    render: prettyDate,
  },
  {
    title: "Detail",
    dataIndex: "detail",
    render: (detail) => (
      <Text style={{ maxWidth: "15em" }} ellipsis={{ tooltip: true }}>
        {detail}
      </Text>
    ),
  },
  {
    title: "Note",
    dataIndex: "note",
    render: (note) =>
      note !== null ? (
        <Text style={{ maxWidth: "15em" }} ellipsis={{ tooltip: true }}>
          {note}
        </Text>
      ) : (
        <NullCell />
      ),
  },
  {
    title: "Severity",
    dataIndex: "severity",
    render: (v) =>
      (
        <Rate
          defaultValue={v}
          character={({ index }) => index + 1}
          style={{ color: "#000" }}
          disabled
        />
      ) || <NullCell />,
  },
];

const Flag = ({ flag, setFlag, onUpdateHandled }) => {
  const { runner } = flag;
  const { user } = runner;

  const [handledLoading, setHandledLoading] = useState(false);

  const related = useApi({
    apiFunc: flagsApi.list,
    defaultParams: {
      "filter{runner}": runner.id,
      "filter{type}": flag.type,
      "filter{-id.eq}": flag.id,
    },
    unpackName: "flags",
    defaultData: [],
  });

  const updateSeverity = async (severity) => {
    const res = await flagsApi.update(flag.id, { severity });
    console.log("RESULT:", res);
    if (res.ok) {
      setFlag(res.data.flag);
    }
  };

  const updateNote = async (formData) => {
    const res = await flagsApi.update(flag.id, { note: formData.text });
    if (res.ok) {
      setFlag(res.data.flag);
    }
    return res;
  };

  const updateHandleLater = async (mins) => {
    const res = await flagsApi.update(flag.id, {
      handle_later: addMinutes(new Date(), mins),
    });
    console.log("HANDLE LATER", res);
    if (res.ok) {
      onUpdateHandled();
    }
  };

  const menu = (
    <Menu>
      {[5, 15, 60].map((mins) => (
        <Menu.Item key={mins} onClick={() => updateHandleLater(mins)}>
          {mins} mins
        </Menu.Item>
      ))}
    </Menu>
  );

  const markHandled = async () => {
    setHandledLoading(true);
    const res = await flagsApi.update(flag.id, {
      handled: true,
    });
    console.log("HANDLED", res);
    setHandledLoading(false);
    if (res.ok) {
      onUpdateHandled();
    }
  };

  return (
    <Card>
      <Row align="middle" justify="space-between">
        <Col>
          <Title level={5} style={{ marginBottom: 0 }}>
            <Link to={`/runners/${runner.id}`}>
              {user.first_name} {user.last_name}{" "}
            </Link>
            {flag.type.replace(/_/g, " ").toLowerCase()}
          </Title>
          <Text>{prettyDate(flag.time)}</Text>
        </Col>
        <Col>
          {flag.handled ? (
            <Button
              icon={<CloseOutlined />}
              onClick={markHandled}
              loading={handledLoading}
            >
              Mark Unhandled
            </Button>
          ) : (
            <Space>
              <Dropdown.Button
                onClick={() => updateHandleLater(5)}
                overlay={menu}
              >
                <ClockCircleOutlined />
                Handle Later
              </Dropdown.Button>

              <Tooltip
                title={flag.severity ? undefined : "Please rate severity"}
              >
                <Button
                  icon={<CheckOutlined />}
                  disabled={flag.severity === null}
                  onClick={markHandled}
                  loading={handledLoading}
                >
                  Mark Handled
                </Button>
              </Tooltip>
            </Space>
          )}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} md={12}>
          <BreakLines text={flag.detail} />
          {flag.bet && (
            <Card style={{ marginTop: 32, marginBottom: 32 }}>
              <Space direction="vertical">
                <BetDetails bet={flag.bet} />
                {flag.bet.betslip && (
                  <Image
                    width={100}
                    height={100}
                    src={flag.bet.betslip}
                    preview={{ mask: <></> }}
                  />
                )}
              </Space>
            </Card>
          )}
        </Col>
        <Col span={24} md={12}>
          <Row align="middle" gutter={[16, 16]}>
            <Col span={6} align="end">
              Severity:
            </Col>
            <Col span={18}>
              <Rate
                defaultValue={flag.severity}
                character={({ index }) => index + 1}
                style={{ color: "#000" }}
                onChange={updateSeverity}
              />
            </Col>
            <Col span={6} align="end">
              Note:
            </Col>
            <Col span={18}>
              <Space>
                {flag.note}
                <AddText
                  title={flag.note === null ? "Add Note" : "Edit"}
                  defaultText={flag.note}
                  submit={updateNote}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      {/* <FlagsTable flags={related} /> */}
      <Table
        size="small"
        columns={columns}
        dataSource={related.data}
        pagination={false}
        scroll={{ x: "max-content" }}
        title={() => `Other occurances (${related.data.length})`}
        rowKey={(record) => record.id}
      />
    </Card>
  );
};

export default Flag;
