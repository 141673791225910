import { Form, Button, Select, Input, Rate } from "antd";
import { useSubmit, useApiList } from "antdash";
import flagsApi from "../../api/flags";
import runnersApi from "../../api/runners";

const AddFlag = ({ onSuccess }) => {
  const runners = useApiList(runnersApi, "runners");

  const submit = (formData) =>
    flagsApi.create({ type: "OTHER", handled: true, ...formData });

  const success = (res) => {
    console.log(res);
    onSuccess();
  };

  const { form, onFinish, submitting } = useSubmit(submit, success);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark="optional"
      onFinish={onFinish}
    >
      <Form.Item
        name="runner"
        label="Runner"
        rules={[{ required: true, message: "Please select a runner." }]}
      >
        <Select
          showSearch
          optionFilterProp="children"
          loading={runners.loading}
        >
          {runners.list.map(({ id, float_currency, user }) => (
            <Select.Option value={id} currency={float_currency} key={id}>
              {user.first_name} {user.last_name} ({float_currency})
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="detail"
        label="Detail"
        rules={[{ required: true, message: "Please provide detail." }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="severity" label="Severity">
        <Rate character={({ index }) => index + 1} style={{ color: "#000" }} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Add Flag
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddFlag;
