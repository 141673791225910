import { useEffect } from "react";
import { FlagOutlined } from "@ant-design/icons";
import { Button, Row, Col, Space } from "antd";
import { useApi } from "antdash";
import { useState } from "react";
import flagsApi from "../api/flags";
import Queue from "../components/Queue";
import AppBreadcrumb from "../components/AppBreadcrumb";
import { routes } from "../../App";
import FlagsTable from "../components/FlagsTable";
import { useHistory } from "react-router-dom";
import Flag from "../components/Flag";

const UnhandledFlag = ({ next }) => {
  const [flag, setFlag] = useState(next.data[0]);

  useEffect(() => {
    setFlag(next.data[0]);
  }, [next.data]);

  return <Flag flag={flag} setFlag={setFlag} onUpdateHandled={next.refresh} />;
};

const StackView = () => {
  const next = useApi({
    apiFunc: flagsApi.list,
    defaultParams: {
      "filter{handled}": false,
      "filter{handle_later.lt}": new Date(),
      sort: ["handle_later", "id"],
      per_page: 1,
    },
    unpackName: "flags",
    defaultData: [],
  });

  return (
    <Queue
      next={next}
      Item={UnhandledFlag}
      emptyMessage={"No unhandled flags."}
      EmptyIcon={FlagOutlined}
    />
  );
};

const TableView = () => {
  const unhandledFlags = useApi({
    apiFunc: flagsApi.list,
    defaultParams: {
      "filter{handled}": false,
    },
    unpackName: "flags",
    defaultData: [],
  });

  return <FlagsTable flags={unhandledFlags} />;
};

const useQueryState = (name, defaultValue) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  console.log(history.location.search, params.get(name));
  const initial = params.has(name)
    ? JSON.parse(params.get(name))
    : defaultValue;

  const [value, setValue] = useState(initial);

  console.log("history", history);

  const updateValue = (update) => {
    setValue(update);
    const addParams = new URLSearchParams({
      ...Object.fromEntries(params.entries()),
      [name]: update,
    }).toString();
    console.log("LOL", addParams);
    history.replace(`${history.location.pathname}?${addParams}`);
  };

  return [value, updateValue];
};

const UnhandledFlags = () => {
  const [stackView, setStackView] = useQueryState("stack", "true");

  return (
    <>
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="space-between"
        style={{ marginBottom: 16 }}
      >
        <Col>
          <AppBreadcrumb routes={[routes.FLAGS, routes.UNHANDLED_FLAGS]} />
        </Col>
        <Col>
          <Space>
            <Button
              type={stackView && "primary"}
              onClick={() => setStackView(true)}
            >
              Stack
            </Button>
            <Button
              type={!stackView && "primary"}
              onClick={() => setStackView(false)}
            >
              Table
            </Button>
          </Space>
        </Col>
      </Row>
      {stackView ? <StackView /> : <TableView />}
    </>
  );
};

export default UnhandledFlags;
