import { useState } from "react";
import { Route, Link, useLocation } from "react-router-dom";
import { Layout, Menu, Dropdown, Space, Tooltip } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  LogoutOutlined,
  QuestionCircleTwoTone,
  QuestionCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Restricted } from "antdash";

const { Header, Content, Footer, Sider } = Layout;

function AppNavigator({ logo, routes, user, logout }) {
  const breakpoint = useBreakpoint();
  const { pathname } = useLocation();

  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const toggleCollapsed = () => setSiderCollapsed(!siderCollapsed);

  const siderWidth = !siderCollapsed ? 200 : breakpoint.xs ? 0 : 80;

  const currentPath = pathname.split("/")[1];

  console.log("CURRENT:", pathname, currentPath);

  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
      <Menu.Item key={routes.SETTINGS.path} icon={routes.SETTINGS.icon}>
        <Link to={routes.SETTINGS.path}>{routes.SETTINGS.title}</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="main-layout" hasSider>
      <Sider
        collapsible
        collapsedWidth={breakpoint.xs ? 0 : undefined}
        collapsed={siderCollapsed}
        onCollapse={setSiderCollapsed}
        breakpoint="md"
        trigger={breakpoint.xs ? null : undefined}
        className="scrollbar-hidden"
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Link to="/">
          <div className="logo-container">
            <img
              style={{ width: siderCollapsed ? "75%" : "60%" }}
              src={logo}
              alt="Bookie"
            />
          </div>
        </Link>
        <Menu
          theme="dark"
          defaultSelectedKeys={[`/${currentPath}`]}
          mode="inline"
          onClick={breakpoint.xs ? () => setSiderCollapsed(true) : null}
          key={pathname}
          className="scrollbar-hidden"
          style={{ overflowY: "auto", height: "100%", paddingBottom: 70 }}
        >
          {Object.values(routes).map(
            (route) =>
              route.menu &&
              (!route.superuserOnly || user.is_superuser) && (
                <Menu.Item key={route.path} icon={route.icon}>
                  {route.external ? (
                    <a href={route.path} target="_blank">
                      {route.title}
                    </a>
                  ) : (
                    <Link to={route.path}>{route.title}</Link>
                  )}
                </Menu.Item>
              )
          )}
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{
          marginLeft: breakpoint.xs ? 0 : siderWidth,
          backgroundColor: "var(--primary)",
        }}
      >
        <Header
          className="site-layout-background"
          style={{
            ...styles.header,
            width: `calc(100% - ${breakpoint.xs ? 0 : siderWidth}px)`,
          }}
        >
          {breakpoint.xs ? (
            <div style={styles.triggerContainer}>
              {siderCollapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  onClick={toggleCollapsed}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  onClick={toggleCollapsed}
                />
              )}
            </div>
          ) : (
            <div></div>
          )}
          {breakpoint.xs && (
            <div className="logo-container">
              <Link to="/">
                <img className="logo-mobile" src={logo} alt="Bookie" />
              </Link>
            </div>
          )}
          {/* <div
              className="trigger"
              style={{ ...styles.triggerContainer, textAlign: "end" }}
            > */}
          <Space
            size="middle"
            align="center"
            style={{
              ...styles.triggerContainer,
              justifyContent: "end",
              paddingRight: 20,
            }}
          >
            <Dropdown overlay={userMenu} trigger={["click"]}>
              <span style={{ cursor: "pointer" }}>
                {user.username} <DownOutlined />
              </span>
            </Dropdown>
            {routes.HELP && breakpoint.md && (
              <Tooltip title="Help">
                <Link
                  style={{ color: "unset", fontSize: 20 }}
                  to={routes.HELP.path}
                >
                  {routes.HELP.icon}
                </Link>
              </Tooltip>
            )}
          </Space>
        </Header>
        <Content style={styles.content}>
          {Object.values(routes).map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={
                user.is_superuser || !route.superuserOnly
                  ? route.component
                  : Restricted
              }
            />
          ))}
        </Content>
        {/* <Footer style={styles.footer}>
          4e76a5ed © {new Date().getFullYear()}
        </Footer> */}
      </Layout>
    </Layout>
  );
}

const styles = {
  sider: {
    overflow: "auto",
    minHeight: "100vh",
    position: "fixed",
    left: 0,
    zIndex: 2,
  },
  header: {
    position: "fixed",
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    color: "white",
    zIndex: 4,
  },
  triggerContainer: { width: 150 },
  content: {
    marginTop: 64,
    padding: 16,
    backgroundColor: "#f0f2f5",
  },
  footer: {
    textAlign: "center",
  },
};

export default AppNavigator;
